const OSCAR_PATH = 'oscar_path';

export const getPath = () => {
  const oscarPath = window.localStorage.getItem(OSCAR_PATH);

  if (!oscarPath) {
    return '';
  }
  return oscarPath;
};

export const savePath = (path: string) => {
  window.localStorage.setItem(OSCAR_PATH, path);
};

export const destroyPath = () => {
  window.localStorage.removeItem(OSCAR_PATH);
};

export default { getPath, savePath, destroyPath };
