enum PermissionsEnum {
        // Access
        Login = 0x10,
        AdminPanel = 0x11,

        // Content Levels
        FreeAccess = 0x60,
        SubscriberL1Access = 0x61,
        Internal = 0x62,

        // Features
        ExploreMbdAccess = 0x80,
        CadToolkitAccess = 0x81,
        TrainingAccess = 0x82,
        CoursesAccess = 0x83,
        FileDownloadAccess = 0x84,
        OnlyFreeFileDownloadAccess = 0x85,
        DictionaryCardAccess = 0x86,
        MbdGuidelinesAccess = 0x87,
        ProgressCheckmarkAccess = 0x88,

}

enum ContentAccessLevelsEnum {
    None = 0,
    Free = 1,
    SubscriptionL1 = 2,
    Internal = 100,
}

const permToContentLevelMap: Record<number, ContentAccessLevelsEnum> = {
    [PermissionsEnum.FreeAccess]: ContentAccessLevelsEnum.Free,
    [PermissionsEnum.SubscriberL1Access]: ContentAccessLevelsEnum.SubscriptionL1,
    [PermissionsEnum.Internal]: ContentAccessLevelsEnum.Internal,
};

const contentLevelToPermMap: Record<number, PermissionsEnum> = {
    [ContentAccessLevelsEnum.Free]: PermissionsEnum.FreeAccess,
    [ContentAccessLevelsEnum.SubscriptionL1]: PermissionsEnum.SubscriberL1Access,
    [ContentAccessLevelsEnum.Internal]: PermissionsEnum.Internal,
};

export {
    PermissionsEnum,
    ContentAccessLevelsEnum,
    permToContentLevelMap,
    contentLevelToPermMap
};

