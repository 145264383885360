import { INamedEntity } from '../';

export class PropertyValue implements INamedEntity {
    public id: string;
    public name: string;
    public childValues: PropertyValue[];

    constructor(id: string, name: string, childValues: PropertyValue[]) {
        this.id = id;
        this.name = name;
        this.childValues = childValues;
    }

    get hasChildren(): boolean {
        return this.childValues.length > 0;
    }
}
