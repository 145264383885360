















import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';

@Component
export default class ToastComponent extends Vue {
    @Prop({required: true, default: false}) public value!: boolean;
    @Prop({required: true, default: 6000}) public timeout!: number;
    @Prop({required: true }) public message!: string;
    @Prop({required: true }) public type!: string;
    @Prop({required: false, default: ''}) public color!: string;
    @Prop({required: false, default: false, type: Boolean}) public top!: boolean;

    public mouseover = false;

    set enabled(enabled: boolean) {
        this.$emit('input', enabled);
    }

    get enabled() {
        return this.value;
    }

    // Not exact, since this doesn't callback perfectly. But it works
    @Watch('enabled')
    public onEnabledChange() {
        if (this.enabled) {
            this.enableSnackbar();
        }

    }

    public timeRemaining = 0;
    public interval: number = 0;

    get snackbarTimeRemaining() {
        if (this.timeRemaining <= 0) {
            return 0;
        }
        return 100 - ((this.timeRemaining / this.timeout) * 100);
    }


    // Not exact, since this doesn't callback perfectly. But it works
    public enableSnackbar() {
        this.timeRemaining = this.timeout;

        this.interval = setInterval(() => {
            if (this.mouseover) {
                return;
            }

            this.timeRemaining -= this.timeout / 50;
            if (this.timeRemaining <= 0) {
                this.clearSnackbar();
            }
        }, this.timeout / 50);
    }

    public clearSnackbar() {
        this.enabled = false;
        clearInterval(this.interval);
    }
}
