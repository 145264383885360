import { Version, FileType, PropertyValuePairIds, CreateEditMetadataRequest} from '@/models/entities';
import { CreateEditFileModel } from './CreateEditFileModel';

export class CreateEditFileRequest {
    public id: string = '';
    public name: string;
    public title: string;
    public originalName: string;
    public fileType: FileType;
    public version: Version | undefined;
    public checksum?: string;

    public contentAccessLevel: string;

    public thumbnailId: string | null = null;

    public metadata: CreateEditMetadataRequest;


    public folderId?: string;

    public sourceFileIds: string[];


    public notes: string;

    constructor(
            name: string,
            originalName: string,
            fileExtension: string,
            fileType: string,
            metadata: CreateEditMetadataRequest,
            thumbnailId: string | null,
            contentAccessLevel: string = '',
            folderId?: string,
            version?: Version,
            notes: string = '',
            title: string = '',
            sourceFileIds: string[] = [],
            checksum?: string,
            ) {
        this.name = name;
        this.originalName = originalName;

        this.folderId = folderId;
        this.version = version;

        this.notes = notes;
        this.title = title;
        this.sourceFileIds = sourceFileIds;
        this.thumbnailId = thumbnailId ?? null;

        this.contentAccessLevel = contentAccessLevel;

        this.fileType = {name: fileType, extension: fileExtension};
        this.checksum = checksum;
        this.metadata = metadata;
    }

    /** Creates the Request from the CreateEdit Model */
    public static FromModel(model: CreateEditFileModel, checksum?: string) {
        const metadata = CreateEditMetadataRequest.FromModel(model.metadata);

        const sourceFiles = model.sourceFiles.map((val) => val.id);

        const output = new CreateEditFileRequest(
            model.name,
            model.originalName,
            model.fileExtension,
            model.fileType,
            metadata,
            model.thumbnailId,
            model.contentAccessLevel,
            model.selectedFolder?.id,
            model.version,
            model.notes,
            model.title,
            sourceFiles ? sourceFiles : [],
            checksum);

        output.id = model.id;
        return output;
    }
}
