import { PropertyValuePairIds, SelectedProperty } from '@/models/entities';
import Vue from 'vue';
import { ICloneable } from '@/models/interfaces';

// Used when selecting properties & values in a form
// Such as for a File or a Package
export class SelectedProperties implements ICloneable<SelectedProperties> {
    public properties: SelectedProperty[] = [];

    // Converts the selected values into a format used in requests
    get pairedPropertyValueIds() {
        const pairs: PropertyValuePairIds[] = [];

        this.properties.forEach((item) => {
            if (item.isPaired && item.paired) {
                const prop = item.paired;
                if (prop.principleId !== '' && prop.requiredId !== '') {
                    pairs.push({principleId: prop.principleId , requiredId: prop.requiredId});
                }
            }
        });
        return pairs;
    }

    // Converts the selected values into a format used in requests
    get propertyValueIds() {
        let ids: string[] = [];

        this.properties.forEach((item) => {
            if (item.isSingle && item.singleSelect) {
                ids.push(item.singleSelect.valueId);
            } else if (item.isMulti && item.multiSelect) {
                ids = ids.concat(item.multiSelect.valueIds);
            }
        });

        return ids;
    }

    public add(property: SelectedProperty) {
        Vue.set(this.properties, this.properties.length, property);
    }

    public removeAt(index: number) {
        this.properties.splice(index, 1);
    }

    public Clone() {
        const clone = new SelectedProperties();
        this.properties.forEach((value) => {
            clone.properties.push(value.Clone());
        });
        return clone;
    }
}
