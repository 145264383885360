const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export default {
    required: (value: any) => !!value || 'Required',
    hasElements: (value: []) => value.length > 0 || 'Must select a value',
    email: (value: string) => emailRegex.test(value) || 'Invalid Email Address',
    version: (value: string) => {
        if (!value) { return 'Required'; }
        if (!value.includes('.')) { return 'Expects x.x Format'; }
        if (value.split('.').length !== 2) { return 'Invalid Format'; }
        if (value.split('.').some((x) => x === '')) { return 'Invalid Format'; }
        if (value.split('.').some((x) => isNaN(Number(x)))) { return 'Expects Numerics'; }

        return true;
    },
    maxLength: (maxLength: number) => {
        return (value: string) => value.length <= maxLength || `Max ${maxLength} characters`;
    },
    numeric: (value: string) => !Number.isNaN(Number.parseInt(value, 10)) || 'Must be Numeric'
};
