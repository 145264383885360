import Vue from 'vue';
import axios from 'axios';
import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import { Property } from '@/models/entities';
import ApiService from '@/utility/ApiService';

import { getModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { CreateEditPropertyRequest, CreateEditPairedPropertyRequest } from '@/models/entities/property/createEditPropertyRequest';
import { ApiHelpers } from '@/_helpers';


@Module({ namespaced: true, name: 'Properties', dynamic: true, store: store })
export default class PropertiesStore extends VuexModule {
    private properties: Record<string, Property> = {};

    get PropertiesArray(): Property[] {
        return Object.values(this.properties);
    }

    get KeyedProperties(): Record<string, Property> {
        return this.properties;
    }

    get Property() {
        return (key: string): Property => {
            return this.properties[key];
        };
    }

    // Gets all properties that are flagged as "Standalone"
    get StandaloneProperties(): Property[] {
        const output: Property[] = [];
        for (const id in this.properties) {
            if (this.properties[id].standalone) {
                output.push(this.properties[id]);
            }
        }
        return output;
    }

    @Mutation
    public setPropertiesDictionary(properties: Record<string, Property>) {
        this.properties = properties;
    }

    @Mutation
    public setProperty(property: Property) {
        Vue.set(this.properties, property.id, property);
    }

    @Mutation
    public removeProperty(property: Property) {
        Vue.delete(this.properties, property.id);
    }

    @Action
    public async fetchProperties() {
        const response = await ApiService.Get<Property[]>(ApiRoutes.Admin.Properties.GetAll);
        const propertiesRecord: Record<string, Property> = {};

        if (response.success && response.result) {
            response.result.forEach((prop) => {
                const instance = new Property(
                    prop.id,
                    prop.name,
                    prop.standalone,
                    prop.selectionType,
                    prop.values,
                    prop.hasRequiredProperty,
                    prop.requiredProperty,
                    prop.requiredPropertyId,
                    prop.static);

                propertiesRecord[instance.id] = instance;
            });
            this.context.commit('setPropertiesDictionary', propertiesRecord);
            return;
        }

        if (!response.success) {
            store.dispatch('setErrors', response.errors);
        } else {
            // Success but no results?
            throw new Error('Unknown error occurred, could not retrieve properties');
        }
    }

    @Action
    public async RemoveProperty(property: Property) {
        if (!property.id) {
            throw new Error('Property Id must be set to persist changes');
        }
        return await ApiHelpers.wrapCallAndPerformAction(
            async () => await ApiService.Delete(ApiRoutes.Admin.Properties.Delete(property.id)) ,
            () => {
                this.removeProperty(property);
            }
        );
    }

    @Action
    public async CreateProperty(model: CreateEditPropertyRequest) {
        return await ApiHelpers.wrapCallAndSetValue(
            async () => await ApiService.Post<Property>(ApiRoutes.Admin.Properties.Create, model),
            (value) => {
                this.setProperty(value);
            }
        );
    }

    @Action
    public async CreatePairedProperty(model: CreateEditPairedPropertyRequest) {
        return await ApiHelpers.wrapCallAndSetValue(
            async () => await ApiService.Post<Property>(ApiRoutes.Admin.Properties.CreatePaired, model),
            (value) => {
                this.setProperty(value);
            }
        );
    }

    @Action
    public async EditProperty(model: CreateEditPropertyRequest) {
        if (!model.id) {
            throw new Error('Cannot edit property with a missing Id');
        }
        return await ApiHelpers.wrapCallAndSetValue(
            async () => await ApiService.Post<Property>(ApiRoutes.Admin.Properties.Edit(model.id), model),
            (value) => {
                this.setProperty(value);
            }
        );
    }

    @Action
    public async EditPairedProperty(model: CreateEditPairedPropertyRequest) {
        if (!model.id) {
            throw new Error('Cannot edit property with a missing Id');
        }
        return await ApiHelpers.wrapCallAndSetValue(
            async () => await ApiService.Post<Property>(ApiRoutes.Admin.Properties.EditPaired(model.id), model),
            (value) => {
                this.setProperty(value);
            }
        );
    }
}
