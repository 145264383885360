// tslint:disable: variable-name

const BaseUrl = window.location.origin;
const ApiPrefix = '/api';
const ApiVersion = '/v1';
const ApiBaseUrl = BaseUrl + ApiPrefix + ApiVersion;

const AdminApiBaseUrl = ApiBaseUrl + '/admin';

const AdminBaseUrls = {
    TagBase: `${AdminApiBaseUrl}/tag`,
    TagsBase: `${AdminApiBaseUrl}/tags`,

    PropertyBase: `${AdminApiBaseUrl}/property`,
    PropertiesBase: `${AdminApiBaseUrl}/properties`,

    FileSystemBase: `${AdminApiBaseUrl}/filesystem`,

    FileBase: `${AdminApiBaseUrl}/file`,
    FilesBase: `${AdminApiBaseUrl}/files`,

    PackageBase: `${AdminApiBaseUrl}/package`,
    PackagesBase: `${AdminApiBaseUrl}/packages`,

    StandardBase: `${AdminApiBaseUrl}/standard`,
    StandardsBase: `${AdminApiBaseUrl}/standards`,

    StandardBodyBase: `${AdminApiBaseUrl}/standardBody`,
    StandardBodiesBase: `${AdminApiBaseUrl}/standardBodies`,

    MbdGuidelineBase: `${AdminApiBaseUrl}/mbdGuideline`,
    MbdGuidelinesBase: `${AdminApiBaseUrl}/mbdGuidelines`,

    CourseBase: `${AdminApiBaseUrl}/course`,
    CoursesBase: `${AdminApiBaseUrl}/courses`,

    ThumbnailBase: `${ApiBaseUrl}/thumbnail`,
    AdminThumbnailBase: `${AdminApiBaseUrl}/thumbnail`,
    ThumbnailsBase: `${ApiBaseUrl}/thumbnails`,

    UserManagement: `${AdminApiBaseUrl}/usermanagement`,

    VimeoBase: `${AdminApiBaseUrl}/vimeo`,

    WooBase: `${AdminApiBaseUrl}/woo`,
};

const UserBaseUrls = {

};

const GlobalBaseUrls = {
    ThumbnailBase: `${ApiBaseUrl}/thumbnail`,
    IdentityBase: `${ApiBaseUrl}/identity`,
    ProgressBase: `${ApiBaseUrl}/progress`
};

const ApiRoutes = {
    ApiBaseUrl: ApiBaseUrl,
    AdminBaseUrl: AdminApiBaseUrl,

    User: {
        Content: {
            Search: `${ApiBaseUrl}/content/search`,
            CadToolkits: `${ApiBaseUrl}/content/cadtoolkit`,
            ExploreMbe: `${ApiBaseUrl}/content/explorembe`,
            Training: `${ApiBaseUrl}/content/training`,
            GetFileDownloadUrl: (fileId: string ) =>  `${ApiBaseUrl}/content/file/${fileId}/download`,
            GetFileInfo: (fileId: string ) =>  `${ApiBaseUrl}/content/file/${fileId}`,
            GetSpecialContentNames: `${ApiBaseUrl}/content/specialcontentnames`,
        },
        Course: {
            GetCourse: (courseId: string ) => `${ApiBaseUrl}/content/course/${courseId}`,
            GetCourseModule: (courseId: string, moduleId: string ) => `${ApiBaseUrl}/content/course/${courseId}/module/${moduleId}`,
        },
        MbdGuideline: {
            GetGuideline: (guidelineId: string) => `${ApiBaseUrl}/content/guideline/${guidelineId}`,
            GetAllGuidelines: `${ApiBaseUrl}/content/guidelines`,
            GetCategories: `${ApiBaseUrl}/content/categories`,
        }
    },
    Admin: {
        Tags: {
            GetAll: AdminBaseUrls.TagsBase,
            GetAllQuery: (withCounts: boolean) => `${AdminBaseUrls.TagsBase}?withCounts=${withCounts}`,
            GetById: (tagId: string) => `${AdminBaseUrls.TagBase}/${tagId}`,
            SubmitAggregateChanges: `${AdminBaseUrls.TagsBase}/submit_changes`,
        },
        Properties: {
            GetAll: AdminBaseUrls.PropertiesBase,
            GetById: (propertyId: string ) => `${AdminBaseUrls.PropertyBase}/${propertyId}`,
            Delete: (propertyId: string ) =>  `${AdminBaseUrls.PropertyBase}/${propertyId}`,
            Create: AdminBaseUrls.PropertyBase,
            CreatePaired: `${AdminBaseUrls.PropertyBase}/paired`,
            Edit: (propertyId: string ) =>  `${AdminBaseUrls.PropertyBase}/${propertyId}`,
            EditPaired: (propertyId: string ) =>  `${AdminBaseUrls.PropertyBase}/paired/${propertyId}`,
        },
        FileSystem: {
            GetRoot: AdminBaseUrls.FileSystemBase,
            GetFolder: (folderId: string ) => `${AdminBaseUrls.FileSystemBase}/${folderId}?includeFiles=true`,
            GetFolderFolders: (folderId: string) => `${AdminBaseUrls.FileSystemBase}/${folderId}?includeFiles=false`,
            RenameFolder: (folderId: string) => `${AdminBaseUrls.FileSystemBase}/${folderId}/rename`,
            MoveFolder: (folderId: string) => `${AdminBaseUrls.FileSystemBase}/${folderId}/move`,
            CreateFolder: (folderId: string) => `${AdminBaseUrls.FileSystemBase}/${folderId}`,
            CreateRootFolder: `${AdminBaseUrls.FileSystemBase}/folder`,
        },
        Files: {
            FindDuplicate: AdminBaseUrls.FileBase + '/isDuplicate',
            ChecksumExists: (checksum: string ) =>  `${AdminBaseUrls.FileBase}/checksum/${checksum}/exists`,
            GetAll: AdminBaseUrls.FilesBase,
            GetList: `${AdminBaseUrls.FilesBase}/list`,
            GetById: (fileId: string ) =>  `${AdminBaseUrls.FileBase}/${fileId}`,
            GetDownloadUrl: (fileId: string ) =>  `${AdminBaseUrls.FileBase}/${fileId}/downloadUrl`,
            GetEditDataById: (fileId: string ) =>  `${AdminBaseUrls.FileBase}/${fileId}/edit`,
            EditFile: (fileId: string ) =>  `${AdminBaseUrls.FileBase}/${fileId}/edit`,
            GetDependents: (fileId: string ) =>  `${AdminBaseUrls.FileBase}/${fileId}/dependents`,
            DeleteFile: (fileId: string ) =>  `${AdminBaseUrls.FileBase}/${fileId}`,
            RenameFile: (fileId: string) => `${AdminBaseUrls.FileBase}/${fileId}/rename`,
            MoveFile: (fileId: string) => `${AdminBaseUrls.FileBase}/${fileId}/move`,
            CreateFile: AdminBaseUrls.FileBase,
            UploadFile: (fileId: string ) =>  `${AdminBaseUrls.FileBase}/${fileId}/upload`,
            ReUploadFile: (fileId: string ) =>  `${AdminBaseUrls.FileBase}/${fileId}/upload/replace`,
            GetPaginatedList: (page: string, fileCount: string, sortBy: string) => `${AdminBaseUrls.FilesBase}/${page}/paginated_list/${fileCount}/${sortBy}`,
        },
        Packages: {
            GetAll: AdminBaseUrls.PackagesBase,
            GetList: `${AdminBaseUrls.PackagesBase}/list`,
            GetById: (packageId: string ) =>  `${AdminBaseUrls.PackageBase}/${packageId}`,
            Delete: (packageId: string ) =>  `${AdminBaseUrls.PackageBase}/${packageId}`,
            GetEditDataById: (packageId: string ) =>  `${AdminBaseUrls.PackageBase}/${packageId}/edit`,
            CreatePackage: AdminBaseUrls.PackageBase,
            EditPackage: (packageId: string ) =>  `${AdminBaseUrls.PackageBase}/${packageId}/edit`,
        },
        Standards: {
            GetAll: AdminBaseUrls.StandardsBase,
            GetMany: AdminBaseUrls.StandardsBase,
            GetById: (standardId: string ) =>  `${AdminBaseUrls.StandardBase}/${standardId}`,
            Add: AdminBaseUrls.StandardBase,
            Edit: (standardId: string ) =>  `${AdminBaseUrls.StandardBase}/${standardId}/edit`,
            Delete: (standardId: string ) =>  `${AdminBaseUrls.StandardBase}/${standardId}`,
        },
        StandardBodies: {
            GetAll: AdminBaseUrls.StandardBodiesBase,
            GetById: (standardBodyId: string ) =>  `${AdminBaseUrls.StandardBodyBase}/${standardBodyId}`,
            Add: AdminBaseUrls.StandardBodyBase,
            Edit: (standardBodyId: string ) =>  `${AdminBaseUrls.StandardBodyBase}/${standardBodyId}/edit`,
            Delete: (standardBodyId: string ) =>  `${AdminBaseUrls.StandardBodyBase}/${standardBodyId}`,
        },
        MbdGuideline: {
            GetAll: AdminBaseUrls.MbdGuidelinesBase,
            GetList: `${AdminBaseUrls.MbdGuidelinesBase}/list`,
            GetInstructionsByGuideline: (guidelineId: string ) =>  `${AdminBaseUrls.MbdGuidelineBase}/${guidelineId}/instructions`,
            GetAllCategories: `${AdminBaseUrls.MbdGuidelinesBase}/categories`,
            GetById: (guidelineId: string ) =>  `${AdminBaseUrls.MbdGuidelineBase}/${guidelineId}`,
            CreateGuideline: AdminBaseUrls.MbdGuidelineBase,
            EditGuideline: (guidelineId: string ) =>  `${AdminBaseUrls.MbdGuidelineBase}/${guidelineId}/edit`,
            SubmitAggregateChanges: `${AdminBaseUrls.MbdGuidelinesBase}/categories/edit`,
            Delete: (guidelineId: string) => `${AdminBaseUrls.MbdGuidelineBase}/${guidelineId}`,
        },
        Course: {
            GetAll: AdminBaseUrls.CoursesBase,
            GetById: (courseId: string ) =>  `${AdminBaseUrls.CourseBase}/${courseId}`,
            GetModuleById: (courseId: string, moduleId: string ) =>  `${AdminBaseUrls.CourseBase}/${courseId}/module/${moduleId}`,
            Delete: (courseId: string ) =>  `${AdminBaseUrls.CourseBase}/${courseId}`,
            GetEditDataById: (courseId: string ) =>  `${AdminBaseUrls.CourseBase}/${courseId}/edit`,
            GetImageById: (courseId: string ) =>  `${AdminBaseUrls.CourseBase}/${courseId}/image`,
            Create: AdminBaseUrls.CourseBase,
            Edit: (courseId: string ) =>  `${AdminBaseUrls.CourseBase}/${courseId}/edit`,
        },
        UserManagement: {
            GetUsers: `${AdminBaseUrls.UserManagement}/users`,
            GetRoleNames: `${AdminBaseUrls.UserManagement}/roles/names`,
            GetRoles: `${AdminBaseUrls.UserManagement}/roles`,
            GetPermissions: `${AdminBaseUrls.UserManagement}/permissions`,
            GetUser: (username: string) => `${AdminBaseUrls.UserManagement}/user/${username}`,
            ActivateUser: (username: string) => `${AdminBaseUrls.UserManagement}/user/${username}/activate`,
            DeActivateUser: (username: string) => `${AdminBaseUrls.UserManagement}/user/${username}/deactivate`,
            UpdateUserRole: (username: string) => `${AdminBaseUrls.UserManagement}/user/${username}/role`,
            DeleteUser: (username: string) => `${AdminBaseUrls.UserManagement}/user/${username}`, // DELETE
            GetSpecialContentNames: `${AdminBaseUrls.UserManagement}/specialcontent/names`,
            UpdateUserSpecialContent: (username: string) => `${AdminBaseUrls.UserManagement}/user/${username}/specialcontent`,
            EditSpecialContent: (specialContentID: string) => `${AdminBaseUrls.UserManagement}/specialcontent/${specialContentID}`, // POST
            DeleteSpecialContent: (specialContentID: string) => `${AdminBaseUrls.UserManagement}/specialcontent/${specialContentID}`, // DELETE
            AddSpecialContent: `${AdminBaseUrls.UserManagement}/specialcontent`, // POST
            CreateRole: `${AdminBaseUrls.UserManagement}/role`, // POST
            EditRole: (roleName: string) => `${AdminBaseUrls.UserManagement}/role/${roleName}`, // POST
            DeleteRole: (roleName: string) => `${AdminBaseUrls.UserManagement}/role/${roleName}`, // DELETE
            AddPermToRole: (roleName: string, permId: string) => `${AdminBaseUrls.UserManagement}/role/${roleName}/perms/${permId}`, // POST
            RemovePermFromRole: (roleName: string, permId: string) => `${AdminBaseUrls.UserManagement}/role/${roleName}/perms/${permId}`, // DELETE
            ChangeRoleLevel: (roleName: string, level: number) => `${AdminBaseUrls.UserManagement}/role/${roleName}/level/${level}`, // POST

            GetDeniedUsers: `${AdminBaseUrls.UserManagement}/denied-users`,
            CreateDeniedUser: `${AdminBaseUrls.UserManagement}/denied-users/create`,
            RemoveDeniedUser: (email: string) => `${AdminBaseUrls.UserManagement}/denied-users/${email}/remove`
        },
        MiscData: {
            GetContentAccessLevels: `${AdminApiBaseUrl}/contentAccess/levels`
        },
        Vimeo: {
            GetVideosList: `${AdminBaseUrls.VimeoBase}/videos`,
            GetVideosListWithQuery: (query: string) => `${AdminBaseUrls.VimeoBase}/videos/query/${query}`
        },
        Woo: {
            GetNewUsersList: `${AdminBaseUrls.WooBase}/users`
        },
        Thumbnails: {
            GetAll: AdminBaseUrls.ThumbnailsBase,
            Get: (thumbnailId: string ) =>  `${AdminBaseUrls.ThumbnailBase}/${thumbnailId}`,
            Create: AdminBaseUrls.AdminThumbnailBase,
        },
    },
    Global: {
        Identity: {
            Register: `${GlobalBaseUrls.IdentityBase}/register`,
            CreateUser: `${GlobalBaseUrls.IdentityBase}/createuser`,
            EditUser: `${GlobalBaseUrls.IdentityBase}/edituser`,
            Login: `${GlobalBaseUrls.IdentityBase}/login`,
            GetUser: (userID: string) => `${GlobalBaseUrls.IdentityBase}/user/${userID}`,
            Validate: `${GlobalBaseUrls.IdentityBase}/validate`,
            Refresh: `${GlobalBaseUrls.IdentityBase}/refresh`,
            SendEmailVerification: `${GlobalBaseUrls.IdentityBase}/send_email_verification`,
            ReSendEmailVerification: `${GlobalBaseUrls.IdentityBase}/resend_email_verification`,
            SendPasswordReset: `${GlobalBaseUrls.IdentityBase}/send_password_reset`,
            RequestPasswordReset: `${GlobalBaseUrls.IdentityBase}/request_password_reset`,
            SendDowngradeUserEmail: `${GlobalBaseUrls.IdentityBase}/send_downgrade_user_email`,
            ResetPassword: `${GlobalBaseUrls.IdentityBase}/reset_password`,
            VerifyEmailAndSetupAccount: `${GlobalBaseUrls.IdentityBase}/verify_and_setup`,
            ConfirmEmail: `${GlobalBaseUrls.IdentityBase}/confirm_email`,
            SendAssessment: `${GlobalBaseUrls.IdentityBase}/send_assessment`,
        },
        Progress: {
            CreateCourseProgress: `${GlobalBaseUrls.ProgressBase}/create_course`,
            CreateModuleProgress: `${GlobalBaseUrls.ProgressBase}/create_module`,
            CreateFileProgress: `${GlobalBaseUrls.ProgressBase}/create_file`,
            EditCourseProgress: `${GlobalBaseUrls.ProgressBase}/edit_course`,
            EditModuleProgress: `${GlobalBaseUrls.ProgressBase}/edit_module`,
            EditFileProgress: `${GlobalBaseUrls.ProgressBase}/edit_file`,
            GetAllUserProgress: (username: string) => `${GlobalBaseUrls.ProgressBase}/username/${username}`,
            GetAllUserFileProgress: (username: string) => `${GlobalBaseUrls.ProgressBase}/username/${username}/file`,
            GetCourseProgress: (username: string, courseId: string) => `${GlobalBaseUrls.ProgressBase}/username/${username}/course/${courseId}`,
            GetModuleProgress: (username: string, courseId: string, moduleId: string) => `${GlobalBaseUrls.ProgressBase}/username/${username}/course/${courseId}/module/${moduleId}`,
            GetFileProgress: (username: string, fileId: string) => `${GlobalBaseUrls.ProgressBase}/username/${username}/file/${fileId}`,
        },
        Thumbnails: {
            GetUrl: (thumbnailId: string ) =>  `${GlobalBaseUrls.ThumbnailBase}/${thumbnailId}/url`,
        }
    },


};

export default ApiRoutes;
