import axios, { CancelTokenSource } from 'axios';
import JwtService from '@/utility/JwtService';

export default class HttpHelper {
    public static encode = encodeURIComponent;

    public static getHeaders(contentType: string = 'application/json'): object {
        return {
            'Authorization': `Bearer ${JwtService.getToken()}`,
            'Content-Type': contentType
        };
    }

    public static formatQueryParams(params: GetParams) {
        return Object.keys(params).map((key: string) => {
            return `${this.encode(key)}=${this.encode(params[key])}`;
        }).join('&');
    }

    public static async get(uri: string, params?: GetParams) {
        if (params && Object.keys(params).length > 0) {
            uri = `${uri}?${this.formatQueryParams(params)}`;
        }

        return await axios.get(`${uri}`, {
            headers: this.getHeaders()
        });
    }

    public static async upload(
        uri: string,
        data: FormData,
        cancelToken: CancelTokenSource,
        progressCallback: (progressEvent: ProgressEvent) => void) {
        return await axios.post(`${uri}`, data, {
            headers: {
                'Authorization': `Bearer ${JwtService.getToken()}`,
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: cancelToken.token,
            onUploadProgress: progressCallback
        });
    }

    public static async postMultipart(uri: string, data: any) {
        return await axios.post(`${uri}`, data, {
            headers: this.getHeaders('multipart/form-data'),
        });
    }

    public static async post(uri: string, data: any) {
        return await axios.post(`${uri}`, data, {
            headers: this.getHeaders(),
        });
    }

    public static async postWithProgress(uri: string, data: any, progressCallback: (progressEvent: ProgressEvent) => void) {
        return await axios.post(`${uri}`, data, {
            headers: this.getHeaders(),
            onUploadProgress: progressCallback
        });
    }

    public static async update(uri: string, data: any) {
        return await axios.put(`${uri}`, data, {
            headers: this.getHeaders(),
        });
    }

    public static async put(uri: string, data: any) {
        return await axios.put(`${uri}`, data, {
            headers: this.getHeaders()
        });
    }

    public static async delete(uri: string) {
        return await axios.delete(uri, {
            headers: this.getHeaders()
        });
    }
}
