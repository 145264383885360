import { CreateEditMetadataModel } from '../metadata';
import { VimeoVideoEditResponse } from './VimeoVideoEditResponse';

export class CreateEditVimeoVideoModel {
    public id: string = '';

    public vimeoId: number = 0;
    public videoPicturesId: number = 0;

    public name: string = '';
    public description: string = '';
    public notes: string = '';
    public metadata: CreateEditMetadataModel;

    public index: number = -1;

    constructor() {
        this.metadata = new CreateEditMetadataModel();
    }

    public FromResponse(model: VimeoVideoEditResponse) {
        this.id = model.id;
        this.name = model.name;
        this.description = model.description;
        this.notes = model.notes;
        this.vimeoId = model.vimeoVideoId;
        this.videoPicturesId = model.vimeoPicturesId;
        this.index = model.index;
        this.metadata = new CreateEditMetadataModel();
        this.metadata.FromResponse(model.metadata);
    }

    public Clone() {
        const clone = new CreateEditVimeoVideoModel();

        clone.id = this.id;
        clone.name = this.name;
        clone.description = this.description;
        clone.notes = this.notes;
        clone.vimeoId = this.vimeoId;
        clone.videoPicturesId = this.videoPicturesId;
        clone.index = this.index;
        clone.metadata = this.metadata.Clone();

        return clone;
    }
}
