import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import { Tag } from '@/models/entities';
import ApiService from '@/utility/ApiService';

import store from '@/store/store';
import { arrayPropsToHashByProp, arrayPropsToHashByPropAction } from '@/utility/Utilities';
import { ApiHelpers } from '@/_helpers';
import { TagsAggregateChangeRequest } from '@/models/requests';

@Module({ namespaced: true, name: 'Tags', dynamic: true, store: store })
export default class TagsStore extends VuexModule {
    private _tags: Record<string, Tag> = {};
    private _tagNamesLookup: Record<string, string> = {};
    private _tagUsageCounts: Record<string, number> = {};


    get tagNamesLookup(): Record<string, string> {
        return this._tagNamesLookup;
    }

    get tagUsageCounts(): Record<string, number> {
        return this._tagUsageCounts;
    }

    get Tags(): Tag[] {
        return Object.values(this._tags);
    }

    get tag(): (id: string) => Tag {
        return (id: string) => {
            return this._tags[id];
        };
    }

    /*@Mutation
    public setTags(tags: Record<string, Tag>) {
        this.tags = tags;
    }*/

    @Mutation
    public setTagNamesLookup(values: Record<string, string>) {
        this._tagNamesLookup = values;
    }

    @Mutation
    public setTagUsageCounts(values: Record<string, number>) {
        this._tagUsageCounts = values;
    }

    @Mutation
    public setTags(tags: Tag[]) {
        const tagsObj: Record<string, Tag> = {};
        tags.forEach((tag) => {
            tagsObj[tag.id] = tag;
        });

        this._tags = tagsObj;
    }

    @Action
    public SetTagUsageCounts(values: Record<string, number>) {
        this._tagUsageCounts = values;
    }

    @Action
    public async fetchAll(withCounts: boolean = false) {
        return await ApiHelpers.wrapCallAndSetValue<Tag[]>(
            async () => await ApiService.Get<Tag[]>(ApiRoutes.Admin.Tags.GetAllQuery(withCounts)),

            (values) => {
                this.setTags(values);
                this.setTagUsageCounts(arrayPropsToHashByProp(values, 'id', 'count'));
                this.setTagNamesLookup(arrayPropsToHashByPropAction(values, 'name', 'name', (name: string) => name.toLowerCase()));
            }
        );
    }

    @Action
    public async SubmitAggregateChanges(changes: TagsAggregateChangeRequest) {
        return await ApiHelpers.wrapCall<void>(
            async () => await ApiService.Post<void>(ApiRoutes.Admin.Tags.SubmitAggregateChanges, changes)
        );
    }

    /*@Action
    public async EditTags(tags: Tag[]) {
        return await ApiHelpers.wrapCall<void>(
            async () => await ApiService.Post<void>(ApiRoutes.Admin.Tags.EditMany, tags)
        );
    }

    @Action
    public async DeleteTags(tags: Tag[]) {
        return await ApiHelpers.wrapCall<void>(
            async () => await ApiService.Post<void>(ApiRoutes.Admin.Tags.DeleteMany, tags)
        );
    }*/
}
