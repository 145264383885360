import Vue from 'vue';
import Vuex, { StoreOptions, GetterTree } from 'vuex';
import { ErrorsResponse, ErrorResponse } from '@/models/responses';

Vue.use(Vuex);

const STORE: StoreOptions<State>  = {
  state: {
    errors: [],
    fatalError: '',
  },
  getters: {
    errors: (state) => state.errors,
    fatalError: (state) => state.fatalError,
    hasFatalError: (state) => {
      return (state.fatalError !== undefined)
              && (state.fatalError !== null)
              && (state.fatalError.length > 0);
    }
  },
  mutations: {
    setErrors(state: State, errors: ErrorResponse[]) {
      state.errors = errors;
    },
    setFatalError(state: State, message: string) {
      state.fatalError = message;
    },
  },
  actions: {
    setError(context, error: string) {
      context.commit('setErrors', [error]);
    },

    setErrors(context, errorResponse: ErrorsResponse) {
      const errors: ErrorResponse[]  = Array.from(errorResponse.errors);

      for (const key in errorResponse.fieldErrors) {
        if (errorResponse.fieldErrors.hasOwnProperty(key)) {
            errors.push(errorResponse.fieldErrors[key]);
        }
      }

      context.commit('setErrors', errors);
    },

    setFatalError(context, message: string) {
      context.commit('setFatalError', message);
    },
  },
};

export default new Vuex.Store(STORE);

interface State {
  errors: ErrorResponse[];
  fatalError: string;
}
