
























































































































































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { AuthStore, StateStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';
import rules from '@/utility/ValidationRules';
import ApiService from '@/utility/ApiService';
import ApiRoutes from '@/_common/ApiRoutes';

const authStore = getModule(AuthStore);
const stateStore = getModule(StateStore);

@Component
export default class RegistrationForm extends Vue {
    private email = '';
    private username = '';
    private firstName = '';
    private lastName = '';
    private organization = '';
    private password = '';
    private passwordcheck = '';

    private emailRules = [
      rules.required,
      rules.email
    ];

    private rules = rules;

    private validForm = true;
    private loading = false;
    private accountCreated = false; // Used to change button state

    private async register() {
        if ((this.$refs.form as any).validate()) {
            if (this.password === this.passwordcheck) {
                this.loading = true;

                if (this.username === '') {
                    this.username = this.email;
                }

                const success = await authStore.Register({
                    username: this.username,
                    organization: this.organization,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password
                });
                this.loading = false;

                if (success) {
                    this.accountCreated = true;
                    /*this.$nextTick(() => {
                        if (this.$router.currentRoute.name === 'Login') {
                            this.$router.push({name: 'Home'});
                        } else {
                            this.$router.replace(this.$router.currentRoute.path);
                        }
                    });*/
                }
            }
        }
    }
    private async sendEmailVerification() {
        this.loading = true;
        const success = await authStore.SendEmailVerification({email: this.email});


        if (success) {
          stateStore.SetSuccessToastMessage(`Verification email has been sent to ${this.email}`);
          stateStore.ToggleSuccessToast(true);
          authStore.ClearErrors();
        }

        this.loading = false;
    }

    get errors() {
        return authStore.Errors;
    }

    get fieldErrors() {
        return authStore.FieldErrors;
    }

    get hasErrors() {
        return authStore.HasErrors;
    }

    get authenticated() {
        return authStore.Authenticated;
    }

    get component() {
        if (this.authenticated) {
        return 'layout';
        }
        return 'login';
    }

    private verifyPasswordRule(value: string) {
        return this.passwordcheck === this.password || 'Passwords Must Match';
    }

    private passwordRequires(value: string) {
        return this.password.length > 12 || 'Password Must Be At Least 12 Characters And Must Have At Least One Digit (0-9)';
    }

    private hasFieldError(name: string) {
        return this.getFieldError(name) !== '';
    }

    private getFieldError(name: string) {
        if (this.fieldErrors === undefined) {
            return '';
        }

        if (this.fieldErrors[name] === undefined) {
            return '';
        }

        return this.fieldErrors[name].message;
    }
}

