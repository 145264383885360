


























































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import rules from '@/utility/ValidationRules';
import { AuthStore } from '@/store/modules';
import { LoginForm, RegistrationForm } from '@/components';

const authStore = getModule(AuthStore);

@Component
export default class ForgotPassword extends Vue {
    private email = '';

    private emailRules = [
      rules.required,
      rules.email
    ];
    private emailSent = false;
    private loading = false;
    private validForm = false;

    private backToLogin() {
        authStore.ClearErrors();
        this.$router.push({name: 'Login'});
    }

    private async sendPasswordReset() {
        if ((this.$refs.form as any).validate()) {
            this.loading = true;

            const success = await authStore.RequestPasswordReset(this.email);

            if (success) {
                this.emailSent = true;
            }
            this.loading = false;
        }

    }

}
