import { Version, FileType, PropertyValuePairIds, CreateEditMetadataRequest} from '@/models/entities';
import { CreateEditVimeoVideoRequest } from './CreateEditVimeoVideoRequest';
import { CreateEditCourseModel } from './CreateEditCourseModel';
import { FileData } from '@/models/interfaces';

export class CreateEditCourseRequest {
    [index: string]: any;

    public id: string = '';
    public name: string;
    public description: string;
    public notes: string;
    public thumbnailId: string | null;
    public contentAccessLevel: string;
    public videos: CreateEditVimeoVideoRequest[] = [];
    public metadata: CreateEditMetadataRequest;

    constructor(
            name: string,
            description: string,
            notes: string,
            thumbnailId: string | null,
            videos: CreateEditVimeoVideoRequest[],
            metadata: CreateEditMetadataRequest,
            contentAccessLevel: string = '') {
        this.name = name;
        this.description = description;
        this.notes = notes;
        this.thumbnailId = thumbnailId;
        this.contentAccessLevel = contentAccessLevel;
        this.videos = videos;
        this.metadata = metadata;
    }

    /** Creates the Request from the CreateEdit Model */
    public static FromModel(model: CreateEditCourseModel) {
        const videos = model.videos.map((video) => CreateEditVimeoVideoRequest.FromModel(video));
        const metadata = CreateEditMetadataRequest.FromModel(model.metadata);

        const output = new CreateEditCourseRequest(
            model.name,
            model.description,
            model.notes,
            model.thumbnailId,
            videos,
            metadata,
            model.contentAccessLevel);

        output.id = model.id;
        return output;
    }
}
