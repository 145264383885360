import { ApiResponse } from '@/models';
import store from '@/store/store';

export class ApiHelpers {

    /**
     * Wraps the API call and returns the APIResponse
     * @param func
     */
    public static async wrapCall<TResult>(func: () => Promise<ApiResponse<TResult>>): Promise<ApiResponse<TResult>> {
        const response = await func();
        if (!response.success) {
            store.dispatch('setErrors', response.errors);
        }
        return response;
    }

    /**
     * Wraps the API call and returns the result or undefined
     * @param func
     */
    public static async wrapCallWithResult<TResult>(func: () => Promise<ApiResponse<TResult>>): Promise<TResult | undefined> {
        const response = await func();
        if (!response.success || !response.result) {
            store.dispatch('setErrors', response.errors);
            return;
        } else {
            return response.result;
        }
    }

    public static async wrapCallAndSetValue<TResult>(apiFunc: () => Promise<ApiResponse<TResult>>, setFunc: (value: TResult) => void)
            : Promise<boolean> {
        const response = await apiFunc();

        if (!response.success || !response.result) {
            if (response.errors === undefined) {
                throw new Error('Error Response is undefined, was this call supposed to receive a result?');
            }
            store.dispatch('setErrors', response.errors);
            return false;
        } else {
            setFunc(response.result);
            return true;
        }
    }

    public static async wrapCallAndPerformAction(apiFunc: () => Promise<ApiResponse>, actionFunc: () => void)
            : Promise<boolean> {
        const response = await apiFunc();

        if (!response.success) {
            store.dispatch('setErrors', response.errors);
            return false;
        } else {
            actionFunc();
            return true;
        }
    }

    public static async wrapCallAndPerformAsyncAction(apiFunc: () => Promise<ApiResponse>, actionFunc: () => Promise<void>)
            : Promise<boolean> {
        const response = await apiFunc();

        if (!response.success) {
            store.dispatch('setErrors', response.errors);
            return false;
        } else {
            await actionFunc();
            return true;
        }
    }

    /**
     * Wraps an API call and returns the result of a modification function
     * Handles api errors
     */
    public static async wrapCallAndModifyValue<TResult, TReturn>(
        apiFunc: () => Promise<ApiResponse<TResult>>,
        modifyFunc: (value: TResult) => TReturn)
            : Promise<TReturn | undefined> {
        const response = await apiFunc();

        if (!response.success || !response.result) {
            store.dispatch('setErrors', response.errors);
            return;
        } else {
            return modifyFunc(response.result);
        }
    }
}
