// The payload values of the JWT itself
export class JwtValues {
    public email: string;
    public expiration: number;
    public issuedAt: number;
    public tokenId: string;
    public notBefore: number;
    public subject: string;
    public userId: string;

    /** Array of Role Ids/Names (Enum Values) */
    public roles: string[];

    /** Array of Permission Ids/Names (Enum Values) */
    public permissions: string[];

    constructor(values: RawJwtValues) {
        this.email = values.email;
        this.expiration = values.exp;
        this.issuedAt = values.iat;
        this.tokenId = values.jti;
        this.notBefore = values.nbf;
        this.subject = values.sub;
        this.userId = values.userId;

        this.roles = [];
        this.permissions = [];

        if (typeof(values.role) === 'string') {
            this.roles = [];
            this.roles.push(values.role);
        } else if (Array.isArray(values.role)) {
            this.roles = values.role;
        }

        if (typeof(values.permissions) === 'string') {
            this.permissions = [];
            this.permissions.push(values.permissions);
        } else if (Array.isArray(values.permissions)) {
            this.permissions = values.permissions;
        }
    }

    // Current datetime in unix time
    get now() {
        return Math.floor(Date.now() / 1000);
    }

    public expired() {
        if (this.now > this.expiration) {
            return true;
        }
    }
}
