enum SelectionType {
    SingleSelect = 'Single Select',
    MultiSelect = 'Multi Select'
}

enum ErrorResponseType {
    Validation = 1,
    Auth = 2,
    Unauthorized = 3,
    Server = 4,
    Fatal = 5,
    Offline = 6,
    NotFound = 7
}

enum AuthError {
    LoginError = 100,
    InvalidCredentials = 101,
    UnverifiedEmail = 102,

    RegistrationError = 200,
    UserAlreadyExists = 201,

    EmailVerificationError = 300,
    VerifyEmail_AccountDoesNotExist = 301,
    VerifyEmail_InvalidAccount = 302,
    EmailAlreadyVerified = 303,

    TokenRefreshError = 400,
    JwtNotExpired = 401,
    RefreshTokenDoesNotExist = 402,
    RefreshTokenExpired = 403,
    RefreshTokenInvalidated = 404,
    RefreshTokenUsed = 405,
    RefreshTokenToJwtMismatch = 406,
}

enum FileScanStatusEnum {
    None = 0,
    Scanning = 1,
    Clean = 2,
    Error = 3,
    Infected = 4
}

enum FileUploadStatusEnum {
    None = 0,
    Uploading = 1,
    Uploaded = 2,
    Error = 3
}

enum FileStatusEnum {
    None = 0, // Should not be used, would be a failure state
    NoData = 1, // No data exists
    Pending = 2, // Data Uploaded to Webserver, not scanning or uploaded to S3. Should rarely be visible. Also a catch all for any intermediate steps.
    Scanning = 3, // Scanning for viruses
    Uploading = 4, // Uploading to S3
    Ok = 5, // All processing complete, no issues
    Error = 6, // File is in an error state for one reason or another, refer to Upload or scan status for specific error
}

export {
    SelectionType,
    AuthError,
    ErrorResponseType,
    FileScanStatusEnum,
    FileUploadStatusEnum,
    FileStatusEnum
};

