






import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { StateStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';

const stateStore = getModule(StateStore);

/**
 * Handles the top loading bar during route changes
 */
@Component
export default class LoaderView extends Vue {
    @Prop({required: false, type: String, default: 'primary'}) public color!: string;

    private progressBar = false;

    @Watch('loading')
    public onLoadingChange(val: boolean, oldval: boolean) { // Delay so progress bar can complete cleanly
        if (val === oldval) {
            return;
        }

        if (val === false) {
            // Delay progress bar hiding for a short bit
            setTimeout(() => {
                this.progressBar = false;
                // Delay clearing route steps to avoid interrupting bar exit animation
                setTimeout(() => {
                    stateStore.SetRouteSteps(0);
                }, 500);
            }, 500);
        } else {
            this.progressBar = true;
        }
    }

    get loading() {
        return stateStore.loading;
    }

    set loading(value: boolean) {
        stateStore.SetLoading(value);
    }

    get routeSteps() {
        return stateStore.routeSteps;
    }

    get routeStepsComplete() {
        if (stateStore.routeStepsComplete === 0 && this.progressBar) { // Progress bar is not gone yet
            return this.routeSteps;
        }
        return stateStore.routeStepsComplete;
    }

    get initProgress() {
        if (this.routeSteps === 0) {
            return 0;
        }
        return (this.routeStepsComplete / this.routeSteps) * 100;
    }
}
