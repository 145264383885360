












import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { FilesStore } from '@/app.admin/store/modules';
import { getModule } from 'vuex-module-decorators';
import ApiService from '@/utility/ApiService';
import ApiRoutes from '@/_common/ApiRoutes';
import { ApiHelpers } from '@/_helpers';
import {CreateEditFileProgressRequest} from '@/models/entities/progress';
import { ApiResponse } from '@/models';
import { BasicUserResponse } from '@/app.admin/models';
import AuthStore from '@/store/modules/authStore';
import { EventBus } from '@/app.user/eventBus';

const filesStore = getModule(FilesStore);
const authStore = getModule(AuthStore);

@Component
export default class DownloadFileButton extends Vue {
    @Prop({required: true, type: String}) public fileId!: string;
    @Prop({required: true, type: String}) public fileName!: string;
    @Prop({required: false, default: ''}) public color!: string;

    private async download() {
        const user = await ApiHelpers.wrapCallWithResult<BasicUserResponse>( async () =>
                await ApiService.Get<BasicUserResponse>(ApiRoutes.Global.Identity.GetUser(authStore.TokenValues!.userId))
            );
        const result = await ApiHelpers.wrapCallWithResult<string>(
            async () => await ApiService.Get<string>(ApiRoutes.User.Content.GetFileDownloadUrl(this.fileId))
        );

        if (result) {
            if (user) {
                await this.CreateFileProgress({username: user!.username, fileId: this.fileId});
            }
            EventBus.$emit('file-downloaded', this.fileId);
            window.open(result, '_self');
        }
    }

    public async CreateFileProgress(newProgress: CreateEditFileProgressRequest): Promise<ApiResponse<any>> {
        return await ApiHelpers.wrapCall<any>(
            async () => await ApiService.Post<any>(ApiRoutes.Global.Progress.CreateFileProgress, newProgress)
        );
        // ToDo: Add File entity and set that if success
    }
}
