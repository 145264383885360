import { Version, PropertyValuePairIds } from '@/models/entities';
import { CreateEditMetadataRequest } from '@/models/entities/metadata';
import { CreateEditPackageModel } from './CreateEditPackageModel';

export class CreatePackageRequest {
    public id: string = '';
    public name: string;
    public title: string;
    public notes: string;
    public version: Version | undefined;
    public thumbnailId: string | null;
    public contentAccessLevel: string;

    public metadata: CreateEditMetadataRequest;

    public sourceFileIds: string[];

    constructor(
            name: string,
            metadata: CreateEditMetadataRequest,
            thumbnailId: string | null,
            contentAccessLevel: string = '',
            version?: Version,
            notes: string = '',
            title: string = '',
            sourceFileIds: string[] = []) {
        this.name = name;
        this.metadata = metadata;
        this.thumbnailId = thumbnailId ?? null;
        this.contentAccessLevel = contentAccessLevel;
        this.version = version;
        this.notes = notes;
        this.title = title;
        this.sourceFileIds = sourceFileIds;
    }


    /** Creates the Request from the CreateEdit Model */
    public static FromModel(model: CreateEditPackageModel) {
        const metadata = CreateEditMetadataRequest.FromModel(model.metadata);
        const sourceFileIds = model.sourceFiles.map((val) => val.id);

        const output = new CreatePackageRequest(
            model.name,
            metadata,
            model.thumbnailId,
            model.contentAccessLevel,
            model.version,
            model.notes,
            model.title,
            sourceFileIds ?? []);

        output.id = model.id;
        return output;
    }
}
