

























































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { AuthStore, StateStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';
import rules from '@/utility/ValidationRules';
import { AuthError } from '@/_common';
import { PermissionsEnum } from '@/_common/permission.enums';

const authStore = getModule(AuthStore);
const stateStore = getModule(StateStore);

@Component
export default class LoginForm extends Vue {
    // private email = '';
    private username = ''; // is actually email, need to change fields
    private password = '';

    private emailRules = [
      rules.required,
      rules.email
    ];

    private passwordRules = [
      rules.required
    ];

    private validForm = true;
    private loading = false;
    private tab = 0;

    private userIsAdmin() {
      if (!authStore.TokenValues) {
        return false;
      }
      return authStore.TokenValues!.permissions.indexOf(PermissionsEnum[PermissionsEnum.AdminPanel]) !== -1;
    }

    private async login() {
      if ((this.$refs.form as any).validate()) {
        await authStore.ClearErrors();

        this.loading = true;
        const success = await authStore.Login({username: this.username, password: this.password});
        const routePath: any = localStorage.getItem('oscar_path') || undefined;
        if (success) {
          if (routePath !== undefined) {
            this.$router.push(routePath);
          } else if (!this.userIsAdmin()) {
            window.location.href = '/';
          } else if (this.$router.currentRoute.name === 'Login') {
            this.$router.push({name: 'Home'});
          } else {
            this.$router.replace(this.$router.currentRoute.path);
          }
        } else {
          this.loading = false; // Only stop loading if there was an issue
        }
      }
    }

    private async sendEmailVerification() {
      if ((this.$refs.form as any).validate()) {
        this.loading = true;
        const success = await authStore.SendEmailVerification({email: this.username});

        if (success) {
          stateStore.SetSuccessToastMessage(`Verification email has been sent to ${this.username}`);
          stateStore.ToggleSuccessToast(true);
          authStore.ClearErrors();
        }

        this.loading = false; // Only stop loading if there was an issue
      }
    }

    get errors() {
        return authStore.Errors;
    }

    get fieldErrors() {
      return authStore.FieldErrors;
    }

    get hasUnverifiedEmailError() {
        if (authStore.HasErrors) {
          return authStore.Errors.some((error) => {
            return error.code === AuthError.UnverifiedEmail;
          });
        }

        return false;
    }

    get authenticated() {
        return authStore.Authenticated;
    }

    get component() {
        if (this.authenticated) {
        return 'layout';
        }
        return 'login';
    }

    private hasFieldError(name: string) {
      if (this.fieldErrors === undefined) {
        return false;
      }

      if (this.fieldErrors[name] === undefined) {
        return false;
      }

      return true;
    }

    private getFieldError(name: string) {
      if (!this.hasFieldError(name)) {
        return '';
      }
      return this.fieldErrors[name].message;
    }


}

