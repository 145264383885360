





import MarkdownIt from 'markdown-it';
import MarkdownItSubscript from 'markdown-it-sub';
import MarkdownItSuperscript from 'markdown-it-sup';
import MarkdownItFootnote from 'markdown-it-footnote';
import MarkdownItDeflist from 'markdown-it-deflist';
import MarkdownItAbbreviation from 'markdown-it-abbr';
import MarkdownItInsert from 'markdown-it-ins';
import MarkdownItMark from 'markdown-it-mark';
import MarkdownItTasklists from 'markdown-it-task-lists';
import MarkdownItContainer from 'markdown-it-container';
import MarkdownItToc from 'markdown-it-toc-and-anchor';
import MarkdownItSourceMap from 'markdown-it-source-map';
import MarkdownItLinkAttributes from 'markdown-it-link-attributes';
const DEFAULT_OPTIONS_LINK_ATTRIBUTES = {
  attrs: {
    target: '_blank',
    rel: 'noopener'
  }
};
const DEFAULT_OPTIONS_TASKLISTS = null;
const DEFAULT_OPTIONS_TOC = {
  tocFirstLevel: 2,
  tocLastLevel: 5,
};

import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { VAlert } from 'vuetify/lib';

@Component({
  components: {
    VAlert
  }
})
export default class MarkdownItVue extends Vue {
    @Prop({type: String, required: true}) public content!: string;
    public htmlContent: string = '';

    @Watch('content', { immediate: true })
    public onContentChanged(val: string, oldVal: string) {
        this.$nextTick(() => {
          this.htmlContent = this.markdownIt.render(val);
        });
    }

    private beforeMount() {
      this.htmlContent = this.markdownIt.render(this.content);
    }

    get dynamicComponent() {
      return {
        template: `<div>${this.htmlContent}</div>`
      };
    }

    public markdownIt = new MarkdownIt({linkify: true})
        .use(MarkdownItSubscript)
        .use(MarkdownItSuperscript)
        .use(MarkdownItFootnote)
        .use(MarkdownItDeflist)
        .use(MarkdownItAbbreviation)
        .use(MarkdownItInsert)
        .use(MarkdownItMark)
        .use(MarkdownItSourceMap)
        .use(MarkdownItLinkAttributes, DEFAULT_OPTIONS_LINK_ATTRIBUTES)
        .use(MarkdownItTasklists, DEFAULT_OPTIONS_TASKLISTS)
        .use(MarkdownItToc, DEFAULT_OPTIONS_TOC)
        .use(MarkdownItContainer, 'alert', {
                // validate: function(params: any) {
                //  return params.trim() === 'warning'
                // },
                render: (tokens: any, idx: any) => {
                  if (tokens[idx].nesting === 1) {
                    const tokenParams: string[] = tokens[idx].info.trim().split(' ');
                    const type = tokenParams.length > 1 ? tokenParams[1] : 'info'; // alert type
                    let params = ''; // component params

                    // If there are more than two params, then combine those for the component
                    // tokenParams defualt will be ['alert']
                    if (params.length > 2) {
                      tokenParams.splice(0, 2); // Remove first two args
                      params = tokenParams.join(' ');
                    } else {
                      params = 'outlined dense';
                    }

                    return `<v-alert ${params} type="${type}">`;
                  } else {
                    return '</v-alert>';
                  }
                }
              });
}

