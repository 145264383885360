import { Version } from '@/models';
import { IHasVersion } from '@/models/interfaces';


/**
 * Parses and replaces the version portion of the response with a Version class
 */
const parseVersion = (item: IHasVersion) => {
    const existing = item.version;
    item.version = new Version(existing.majorVersion, existing.minorVersion);
};

/**
 * Parses and replaces the version portion of the response with a Version class
 */
const parseVersions = (items: IHasVersion[]) => {
    items.forEach((item) => {
        parseVersion(item);
    });
};

export {
    parseVersion,
    parseVersions
};
