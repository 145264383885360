const nameof = <T>(name: Extract<keyof T, string>): string => name;

const formatFileSize = (size: number) => {
    if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
    } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
    } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB';
    } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB';
    }
    return size.toString() + ' B';
};

const formatTransferRate = (bytes: number) => {
    const bits = bytes * 8;
    if (bits > 1024 * 1024 * 1024 * 1024) {
        return (bits / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' Tb';
    } else if (bits > 1024 * 1024 * 1024) {
        return (bits / 1024 / 1024 / 1024).toFixed(2) + ' Gb';
    } else if (bits > 1024 * 1024) {
        return (bits / 1024 / 1024).toFixed(2) + ' Mb';
    } else if (bits > 1024) {
        return (bits / 1024).toFixed(2) + ' Kb';
    }
    return bits.toString() + ' b';
};

/**
 * Finds the index of an item by it's key & value
 * @param list The array of values
 * @param key The name of the objects key
 * @param value The key value to look for
 * @returns The index of the item, or -1
 */
const indexOfByKey = (list: any[], key: any, value: any) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i][key] === value) {
            return i;
        }
    }
    return -1;
};

const arrayPropsToHashByPropAction = (list: any[], keyProp: string, valueProp: string, action: (value: any) => any) => {
    const record: Record<string, any> = {};

    list.forEach((value: any) => {
        let key: string = '';
        if (typeof value[keyProp] === 'string') {
            key = action(value[keyProp]);
        } else if (typeof value[keyProp] === 'number') {
            key = action(value[keyProp].toString());
        } else {
            throw new Error('Unsupported key type');
        }


        record[key] = value[valueProp];
    });

    return record;
};

// Creates a record of the specified key prop and value prop
const arrayPropsToHashByProp = (list: any[], keyProp: string, valueProp: string) => {
    const record: Record<string, any> = {};

    list.forEach((value: any) => {
        let key: string = '';
        if (typeof value[keyProp] === 'string') {
            key = value[keyProp];
        } else if (typeof value[keyProp] === 'number') {
            key = value[keyProp].toString();
        } else {
            throw new Error('Unsupported key type');
        }


        record[key] = value[valueProp];
    });

    return record;
};

const arrayToHashByProp = (list: any[], prop: string) => {
    const record: Record<string, any> = {};

    list.forEach((value: any) => {
        let key: string = '';
        if (typeof value[prop] === 'string') {
            key = value[prop];
        } else if (typeof value[prop] === 'number') {
            key = value[prop].toString();
        } else {
            throw new Error('Unsupported key type: ' + typeof value[prop]);
        }


        record[key] = value;
    });

    return record;
};

// Creates a hash with the key being the value of the `prop`, the key is manipulated by the `action`
const arrayToHashByPropAndAction = (list: any[], prop: string, action: (key: any) => any) => {
    const record: Record<string, any> = {};

    list.forEach((value: any) => {
        let key: string = '';
        if (typeof value[prop] === 'string') {
            key = value[prop];
        } else if (typeof value[prop] === 'number') {
            key = value[prop].toString();
        } else {
            throw new Error('Unsupported key type');
        }


        record[action(key)] = value;
    });

    return record;
};

const arrayToHash = (list: string[] | number[]) => {
    const record: Record<string, string | number> = {};

    list.forEach((value: string | number) => {
        if (typeof value === 'number') {
            record[value.toString()] = value;
            return;
        }
        record[value] = value;
    });

    return record;
};

const arrayToMap = (list: string[] | number[]) => {
    const map: Map<string | number, string | number> = new Map();

    list.forEach((value: string | number) => {
        map.set(value, value);
    });

    return map;
};


const groupArray = <T extends {[index: string]: any}>(list: T[], prop: string) => {
    const record: Record<string, T[]> = {};

    list.forEach((value) => {
        if (!((value[prop] as any) in record)) {
            record[value[prop]] = [];
        }

        record[value[prop]].push(value);
    });

    return record;
};

// https://stackoverflow.com/a/27747377
// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
const dec2hex = (dec: number) => {
    return ('0' + dec.toString(16)).substr(-2);
};

// https://stackoverflow.com/a/27747377
// generateId :: Integer -> String
const generateId = (len: number) => {
    const arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
};

function capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }

const simpleDeepCopy = (item: any) => {
    const seen: any[] = [];
    const stringified = JSON.stringify(item, (key, val) => {
        if (val != null && typeof val === 'object') {
            if (seen.indexOf(val) >= 0) {
                return;
            }
            seen.push(val);
        }
        return val;
    });
    return JSON.parse(stringified);
};

export {
    nameof,
    groupArray,
    formatFileSize,
    formatTransferRate,
    indexOfByKey,
    arrayToHash,
    arrayToMap,
    arrayPropsToHashByPropAction,
    arrayPropsToHashByProp,
    arrayToHashByProp,
    arrayToHashByPropAndAction,
    generateId,
    capitalizeFirstLetter,
    simpleDeepCopy
};
