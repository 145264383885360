



















import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { MbdGuideline } from '@/models';
import { SimpleEntity } from '@/models/entities';
import { getModule } from 'vuex-module-decorators';
import { MbdGuidelinesStore } from '@/app.admin/store/modules';
import { EventBus } from '@/app.user/eventBus';

const guidelinesStore = getModule(MbdGuidelinesStore);

@Component
export default class MbdGuidelineSideTile extends Vue {
    @Prop({required: true}) public guidelines!: MbdGuideline[];
    @Prop({required: true}) public guideline!: MbdGuideline;
    @Prop({required: true}) public categories!: SimpleEntity[];

    private guidelineSideItems: any = document.getElementsByClassName('guideline-side-scroll-items');
    private scrollItem: any = document.getElementsByClassName('guideline-side-tile');

    private scrollToGuideline(id: string) {
        for (let i = 0; i < this.guidelineSideItems.length; i++) {
            if (this.guidelineSideItems[i].classList.contains('selected-guideline-side-tile')) {
                this.guidelineSideItems[i].classList.remove('selected-guideline-side-tile');
            }
            if (this.guidelineSideItems[i].id === id) {
                this.guidelineSideItems[i].classList.add('selected-guideline-side-tile');
                this.guidelineSideItems[i].scrollIntoView();
                window.scrollTo(0, 0);
            }
        }
    }

    private routeToGuideline(guidelineId: string) {
        if (guidelineId !== '') {
            history.pushState({}, '', '/#/mbd-guidelines/' + decodeURIComponent(guidelineId));
            EventBus.$emit('change-guidelineId', guidelineId);
        } else if (guidelineId === '') {
            history.pushState({}, '', '/#/mbd-guidelines');
        }
    }

    @Watch('guideline')
    private onGuidelineChange(newValue: string, oldValue: string) {
        if (newValue === oldValue) {
            return;
        } else {
            if (newValue === '') {
                for (let i = 0; i < this.guidelineSideItems.length; i++) {
                    if (this.guidelineSideItems[i].classList.contains('selected-guideline-side-tile')) {
                        this.guidelineSideItems[i].classList.remove('selected-guideline-side-tile');
                    }
                }
                this.scrollItem[0].scrollTo(0, 0);
                window.scrollTo(0, 0);
            } else {
                this.scrollToGuideline(newValue);
            }
        }
    }
}

