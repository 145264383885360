import Vue from 'vue';
import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';

import { CreateEditFileRequest, JwtValues } from '@/models';
import ApiService from '@/utility/ApiService';
import { ApiResponse } from '@/models/responses';

import store from '@/store/store';
import { SimpleFile, FileResponse, FileEditResponse, FilePagination} from '@/models/entities';
import { parseVersions, ApiHelpers } from '@/_helpers';
import { CreateEditFileModel } from '@/models/entities/file';

@Module({ namespaced: true, name: 'Files', dynamic: true, store: store })
export default class FilesStore extends VuexModule {

    private _filesList: SimpleFile[] = [];
    private _files: Record<string, FileResponse> = {};

    get file() {
        return (fileId: string) => {
            return this._files[fileId];
        };
    }

    get files() {
        return Object.values(this._files);
    }

    get filesList() {
        return this._filesList;
    }

    @Mutation
    private setList(items: SimpleFile[]) {
        this._filesList = items;
    }

    @Mutation
    private setFiles(files: FileResponse[]) {
        files.forEach((item) => {
            Vue.set(this._files, item.id, item);
        });
    }

    @Mutation
    private setFile(file: FileResponse) {
        Vue.set(this._files, file.id, file);
    }

    @Mutation
    private removeFile(fileId: string) {
        Vue.delete(this._files, fileId);
        const index = this._filesList.findIndex((x) => x.id === fileId);
        if (index !== -1) {
            Vue.delete(this._filesList, index);
        }
    }

    @Action
    public async CreateFile(newFile: CreateEditFileRequest): Promise<ApiResponse<any>> {

        return await ApiHelpers.wrapCall<FileResponse>(
            async () => await ApiService.Post<FileResponse>(ApiRoutes.Admin.Files.CreateFile, newFile)
        );
        // ToDo: Add File entity and set that if success
    }

    @Action({rawError: true})
    public async EditFile(file: CreateEditFileRequest): Promise<boolean> {
        if (!file.id) {
            throw Error('Cannot edit file because the file model has no Id');
        }

        return ApiHelpers.wrapCallAndSetValue<FileResponse>(
            async () => await ApiService.Post<FileResponse>(ApiRoutes.Admin.Files.EditFile(file.id), file),
            (value) => {
                this.setFile(value);
            }
        );
    }

    @Action
    public async DeleteFile(fileId: string) {
        if (!fileId) {
            throw Error('Cannot delete file because the package model has no Id');
        }

        return await ApiHelpers.wrapCallAndPerformAction(
            async () => await ApiService.Delete(ApiRoutes.Admin.Files.DeleteFile(fileId)),
            () => {
                this.removeFile(fileId);
            }
        );
    }

    @Action
    public async fetchFile(fileId: string) {
        return await ApiHelpers.wrapCallAndSetValue<FileResponse>(
            async () => await ApiService.Get<FileResponse>(ApiRoutes.Admin.Files.GetById(fileId)) ,
            (value) => this.setFile(value)
        );
    }

    @Action
    public async fetchFileEditData(fileId: string) {
        return await ApiHelpers.wrapCallAndSetValue<FileEditResponse>(
            async () => await ApiService.Get<FileEditResponse>(ApiRoutes.Admin.Files.GetEditDataById(fileId)) ,
            (value) => {
                const editModel = new CreateEditFileModel();
                editModel.FromResponse(value);
            }
        );
    }

    @Action
    public async fetchFiles() {
        return await ApiHelpers.wrapCallAndSetValue<FileResponse[]>(
            async () => await ApiService.Get<FileResponse[]>(ApiRoutes.Admin.Files.GetAll) ,
            (values) => {
                parseVersions(values);
                this.setFiles(values);
            }
        );
    }

    @Action // Fetches a list of SimpleFile[] from the server
    public async fetchList() {
        return await ApiHelpers.wrapCallAndSetValue<SimpleFile[]>(
            async () => await ApiService.Get<SimpleFile[]>(ApiRoutes.Admin.Files.GetList) ,
            (values) => {
                parseVersions(values);
                this.setList(values);
            }
        );
    }
}

