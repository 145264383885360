import { CreateEditMetadataModel } from '@/models/entities/metadata/CreateEditMetadataModel';
import { CourseEditResponse } from './CourseEditResponse';
import { CreateEditVimeoVideoModel } from './CreateEditVimeoVideoModel';
import { FileData } from '@/models/interfaces';

export class CreateEditCourseModel {

    public id: string = '';
    public name: string = '';
    public description: string = '';
    public notes: string = '';
    public thumbnailId: string | null = null;
    public contentAccessLevel: string = '';
    public videos: CreateEditVimeoVideoModel[] = [];
    public metadata: CreateEditMetadataModel;

    constructor() {
        this.metadata = new CreateEditMetadataModel();
    }

    public FromResponse(model: CourseEditResponse) {
        this.id = model.id;
        this.name = model.name;
        this.description = model.description;
        this.notes = model.notes;
        this.thumbnailId = model.thumbnailId ?? null;
        this.contentAccessLevel = model.contentAccessLevel;
        this.metadata = new CreateEditMetadataModel();
        this.metadata.FromResponse(model.metadata);

        model.videos.forEach((video) => {
            const videoModel = new CreateEditVimeoVideoModel();
            videoModel.FromResponse(video);
            this.videos.push(videoModel);
        });
    }
}
