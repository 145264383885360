






import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import rules from '@/utility/ValidationRules';
import { AuthStore, StateStore } from '@/store/modules';
import { LoginForm, RegistrationForm } from '@/components';

const authStore = getModule(AuthStore);

@Component({
    components: {
        LoginForm
    }
})
export default class LoginTabs extends Vue {
    private tab = 0;

    get sliderColor() {
        if (this.tab === 0) {
            return 'primary--text text--darken-1';
        } else if (this.tab === 1) {
            return 'orange--text text--darken-1';
        }
    }

    @Watch('tab')
    private onTabChanged(val: number) {
        authStore.ClearErrors();
    }
}
