import { ICloneable } from './interfaces';

/**
 * @summary An edit model used to house original and modified values of an object
 * @description This requires T to implement ICloneable, and as such all computed values & functions are preserved
 */
export class ClonedEditModel<T extends ICloneable<T>> {
    // These are for external state management
    public editing: boolean = false;
    public modified: boolean = false;
    public deleted: boolean = false;
    public new: boolean;

    /** The original item, this will never change. */
    public original: T;

    /** The temporary working value, this is what should be edited directly. */
    public temp: T;

    /** The committed, modified value. This is what should be referenced for display. */
    public value: T;

    /** The previous value before the last value was committed. Essentially a history value. */
    public previousValue: T;

    constructor(original: T, isNew = false) {
        this.original = original;
        this.new = isNew;
        this.value = original.Clone();
        this.temp = original.Clone();
        this.previousValue = original.Clone();
    }

    /** Commits the current value to the previousValue field and the temp value to the value field */
    public commit() {
        this.previousValue = this.value.Clone();
        this.value = this.temp.Clone();
    }

    /** Reset the temp variable to the current value, such as after cancelling an edit */
    public resetTemp() {
        this.temp = this.value.Clone();
    }

    /** Restores the previously set value to previousValue, such as when a commit has to be reverted */
    public restorePreviousValue() {
        if (JSON.stringify(this.previousValue) === JSON.stringify(this.original)) {
            this.modified = false;
        }
        this.value = this.previousValue.Clone();
    }

    /** Restores the entire object back to it's original value */
    public restore() {
        this.value = this.original.Clone();
        this.previousValue = this.original.Clone();
        this.temp = this.original.Clone();
        this.modified = false;
    }
}
