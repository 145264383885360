export interface IVersion {
    majorVersion: number;
    minorVersion: number;
    previousEntityId?: string;
}

export class Version implements IVersion {
    public majorVersion: number;
    public minorVersion: number;
    public previousEntityId?: string;

    constructor(majorVersion: number, minorVersion: number) {
        this.majorVersion = majorVersion;
        this.minorVersion = minorVersion;
    }

    get number() {
        if (this.majorVersion === 0) {
            return Number.parseFloat(`0.${this.minorVersion}`);
        }
        return Number.parseFloat(`${this.majorVersion}.${this.minorVersion}`);
    }

    public greaterThan(other: Version) {
        if (other.majorVersion === this.majorVersion) {
            return other.minorVersion <= this.minorVersion;
        }
        return other.majorVersion <= this.majorVersion;
    }
}
