// tslint:disable: variable-name

import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import store from '@/store/store';
import { arrayToHash } from '@/utility/Utilities';
import { SortByOption, SearchFilters, SearchFlag, EntityFilterParams } from '@/models/interfaces';

@Module({ namespaced: true, name: 'Search', dynamic: true, store: store })
export default class SearchStore extends VuexModule {
    public _search: string = '';
    public _searchFilterFlags: SearchFilters = {
        tags: false,
        date: false,
        properties: false
    };

    public _searchFlags: SearchFlag[] = [];

    public _sortBy: SortByOption = { text: '', prop: '' };
    public _sortByOptions: SortByOption[] = [];
    public _sortDesc: boolean = false;

    public _tagsFilterHash: Record<string, any> = {};
    public _exclusiveTags: boolean = true;

    public _propertiesFilterHash: Record<string, any> = {};
    public _exclusiveProperties: boolean = true;

    /**
     * Gets the filter params for filtering entities
     * Notes that the items prop is empty and needs to be filled in
     */
    get entityFilterParams(): EntityFilterParams {
        return  {
            items: [],
            search: this.search,
            searchFlags: this.searchFlags,
            filterFlags: this.searchFilterFlags,
            tagsFilter: this.tagsFilterHash,
            exclusiveTags: this.exclusiveTags,
            propertiesFilter: this.propertiesFilterHash,
            exclusiveProperties: this.exclusiveProperties
        };
    }

    get search() {
        return this._search;
    }

    get searchFilterFlags() {
        return this._searchFilterFlags;
    }

    get searchFlags() {
        return this._searchFlags;
    }

    get sortBy() {
        return this._sortBy;
    }

    get sortByOptions() {
        return this._sortByOptions;
    }


    get sortDesc() {
        return this._sortDesc;
    }

    get tagsFilter() {
        return Object.values(this._tagsFilterHash);
    }

    get tagsFilterHash() {
        return this._tagsFilterHash;
    }

    get exclusiveTags() {
        return this._exclusiveTags;
    }

    get propertiesFilter() {
        return Object.values(this._propertiesFilterHash);
    }

    get propertiesFilterHash() {
        return this._propertiesFilterHash;
    }

    get exclusiveProperties() {
        return this._exclusiveProperties;
    }

    /* ---- Tags Getters ---- */

    get tagActiveInFilter() {
        return (id: string) => {
            return id in this._tagsFilterHash;
        };
    }

    get propertyActiveInFilter() {
        return (name: string) => {
            return name in this._propertiesFilterHash;
        };
    }

    //#region Sorting
        @Mutation
        private setSortBy(value: SortByOption) {
            this._sortBy = value;
        }

        @Mutation
        private setSortByOptions(value: SortByOption[]) {
            this._sortByOptions = value;
        }

        @Mutation
        private setSortDesc(value: boolean) {
            this._sortDesc = value;
        }

        @Action
        public async SetSortBy(value?: SortByOption) {
            if (!value) {
                value = { text: '', prop: ''};
            }
            this.setSortBy(value);
        }

        @Action
        public async SetSortByOptions(value: SortByOption[]) {
            this.setSortByOptions(value);
        }

        @Action
        public async ClearSortByOptions() {
            this.setSortByOptions([]);
        }

        @Action
        public async SetSortDesc(value: boolean) {
            this.setSortDesc(value);
        }
    //#endregion

    //#region Search
    @Mutation
    private setSearch(value: string) {
        this._search = value;
    }

    @Mutation
    private setSearchFilterFlags(value: SearchFilters) {
        this._searchFilterFlags = value;
    }

    @Mutation
    private setSearchFlags(value: SearchFlag[]) {
        this._searchFlags = value;
    }

    @Action
    public async ClearSearchFilterFlags() {
        this.setSearchFilterFlags({tags: false, properties: false, date: false});
    }

    @Action
    public async ClearSearchFlags() {
        this.setSearchFlags([]);
    }

    @Action
    public async ClearSearch() {
        this.setSearch('');
    }

    @Action
    public async SetSearch(value: string) {
        this.setSearch(value);
    }

    @Action
    public async SetSearchFilterFlags(value: SearchFilters) {
        this.setSearchFilterFlags(value);
    }

    @Action
    public async SetSearchFlags(value: SearchFlag[]) {
        this.setSearchFlags(value);
    }

    //#endregion

    //#region Tags
        @Mutation
        private _setTagsFilter(value: string[]) {
            this._tagsFilterHash = arrayToHash(value);
        }

        @Mutation
        private _setExclusiveTags(value: boolean) {
            this._exclusiveTags = value;
        }

        @Mutation
        private _addTagToFilter(id: string) {
            Vue.set(this._tagsFilterHash, id, id);
        }

        @Action
        public async SetTagsFilter(value: string[]) {
            this._setTagsFilter(value);
        }

        @Action
        public async SetExclusiveTags(value: boolean) {
            this._setExclusiveTags(value);
        }

        @Action
        public async addOrRemoveTagFilter(id: string) {
            if (!this.tagActiveInFilter(id)) {
                this._addTagToFilter(id);
            }
        }
    //#endregion

    //#region Properties
        @Mutation
        private _setPropertiesFilter(value: string[]) {
            this._propertiesFilterHash = arrayToHash(value);
        }

        @Mutation
        private _setExclusiveProperties(value: boolean) {
            this._exclusiveProperties = value;
        }

        @Mutation
        private _addPropertyToFilter(name: string) {
            Vue.set(this._tagsFilterHash, name, name);
        }

        @Action
        public async SetPropertiesFilter(value: string[]) {
            this._setPropertiesFilter(value);
        }

        @Action
        public async SetExclusiveProperties(value: boolean) {
            this._setExclusiveProperties(value);
        }

        @Action
        public async addOrRemovePropertyFilter(name: string) {
            if (!this.propertyActiveInFilter(name)) {
                this._addPropertyToFilter(name);
            }
        }
//#endregion

}
