import { SingleSelectValues, MultiSelectValues, PairedValues } from './SelectionInterfaces';
import { ICloneable } from '@/models/interfaces';

export class SelectedProperty implements ICloneable<SelectedProperty> {
    public key: string; // Used to distinguish in v-for
    public propertyId: string;

    public singleSelect?: SingleSelectValues;
    public multiSelect?: MultiSelectValues;
    public paired?: PairedValues;

    constructor(key: string, propertyId: string) {
        this.key = key;
        this.propertyId = propertyId;
    }

    get isSingle() {
        return this.singleSelect !== undefined;
    }
    get isMulti() {
        return this.multiSelect !== undefined;
    }
    get isPaired() {
        return this.paired !== undefined;
    }

    public Clone() {
        const clone = new SelectedProperty(this.key, this.propertyId);

        clone.singleSelect = this.singleSelect;
        clone.multiSelect = this.multiSelect;
        clone.paired = this.paired;

        return clone;
    }
}
