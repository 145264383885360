
















































import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component
export default class DocumentDialog extends Vue {
    @PropSync('dialog', {required: true, type: Boolean}) private syncedDialog!: string; // document, or video
}
