import Vue from 'vue';
import axios from 'axios';
import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import { StandardBody, Standard } from '@/models/entities';
import ApiService from '@/utility/ApiService';

import store from '@/store/store';
import { ApiHelpers } from '@/_helpers';
import { arrayToHashByProp, arrayPropsToHashByProp } from '@/utility/Utilities';

@Module({ namespaced: true, name: 'Standards', dynamic: true, store: store })
export default class StandardsStore extends VuexModule {
    private standards: Record<string, Standard> = {};
    private standardsNamesLookup: Record<string, string> = {};
    private standardBodies: Record<string, StandardBody> = {};

    get StandardsArray(): Standard[] {
        return Object.values(this.standards);
    }

    get KeyedStandards(): Record<string, Standard> {
        return this.standards;
    }

    get StandardsNamesLookup(): Record<string, string> {
        return this.standardsNamesLookup;
    }

    get Standard() {
        return (key: string): Standard => {
            return this.standards[key];
        };
    }

    get StandardBodiesArray(): StandardBody[] {
        return Object.values(this.standardBodies);
    }

    get KeyedStandardBodies(): Record<string, StandardBody> {
        return this.standardBodies;
    }

    get StandardBody() {
        return (key: string): StandardBody => {
            return this.standardBodies[key];
        };
    }


    @Mutation
    private setStandardsDictionary(standards: Record<string, Standard>) {
        this.standards = standards;
    }

    @Mutation
    private setManyStandards(standards: Standard[]) {
        for (let i = 0; i < standards.length; i++) {
            Vue.set(this.standards, standards[i].id, standards[i]);
        }
        this.standardsNamesLookup = arrayPropsToHashByProp(Object.values(this.standards), 'id', 'name');
    }

    @Mutation
    private setStandard(standard: Standard) {
        Vue.set(this.standards, standard.id, standard);
        this.standardsNamesLookup = arrayPropsToHashByProp(Object.values(this.standards), 'id', 'name');
    }

    @Mutation
    private removeStandard(standard: Standard) {
        Vue.delete(this.standards, standard.id);
        this.standardsNamesLookup = arrayPropsToHashByProp(Object.values(this.standards), 'id', 'name');
    }

    @Mutation
    private setStandardsNamesLookup(standardsNamesLookup: Record<string, string>) {
        this.standardsNamesLookup = standardsNamesLookup;
    }

    @Mutation
    private setStandardBodiesDictionary(standardBodies: Record<string, StandardBody>) {
        this.standardBodies = standardBodies;
    }

    @Mutation
    private setStandardBody(standardBody: StandardBody) {
        Vue.set(this.standardBodies, standardBody.id, standardBody);
    }

    @Mutation
    private removeStandardBody(standardBody: StandardBody) {
        Vue.delete(this.standardBodies, standardBody.id);
    }

    @Action({rawError: true})
    public async fetchStandards() {
        return await ApiHelpers.wrapCallAndSetValue<Standard[]>(
            async () => await ApiService.Get<Standard[]>(ApiRoutes.Admin.Standards.GetAll) ,
            (values) => {
                this.setStandardsDictionary(arrayToHashByProp(values, 'id'));
                this.setStandardsNamesLookup(arrayPropsToHashByProp(values, 'id', 'name'));
            }
        );
    }

    @Action({rawError: true})
    public async fetchManyStandards(ids: string[]) {
        return await ApiHelpers.wrapCallAndSetValue<Standard[]>(
            async () => await ApiService.Post<Standard[]>(ApiRoutes.Admin.Standards.GetMany, {ids: ids}) ,
            (values) => {
                this.setManyStandards(values);
            }
        );
    }

    @Action
    public async fetchStandard(id: string) {
        return await ApiHelpers.wrapCallAndSetValue<Standard>(
            async () => await ApiService.Get<Standard>(ApiRoutes.Admin.Standards.GetById(id)) ,
            (value) => {
                this.setStandard(value);
            }
        );
    }

    @Action
    public async fetchStandardBody(id: string) {
        return await ApiHelpers.wrapCallAndSetValue<StandardBody>(
            async () => await ApiService.Get<StandardBody>(ApiRoutes.Admin.StandardBodies.GetById(id)) ,
            (value) => {
                this.setStandardBody(value);
            }
        );
    }

    @Action({rawError: true})
    public async fetchStandardBodies() {
        return await ApiHelpers.wrapCallAndSetValue<StandardBody[]>(
            async () => await ApiService.Get<StandardBody[]>(ApiRoutes.Admin.StandardBodies.GetAll) ,
            (values) => {
                this.setStandardBodiesDictionary(arrayToHashByProp(values, 'id'));
            }
        );
    }

    @Action({rawError: true})
    public async addStandard(standard: Standard) {
        return await ApiHelpers.wrapCallAndSetValue<Standard>(
            async () => await ApiService.Post<Standard>(ApiRoutes.Admin.Standards.Add, standard) ,
            (value) => {
                this.setStandard(value);
            }
        );
    }

    @Action({rawError: true})
    public async updateStandard(standard: Standard) {
        if (!standard.id) {
            throw new Error('Standard Id must be set to persist changes');
        }
        return await ApiHelpers.wrapCallAndSetValue<Standard>(
            async () => await ApiService.Post<Standard>(ApiRoutes.Admin.Standards.Edit(standard.id), standard) ,
            (value) => {
                this.setStandard(value);
            }
        );
    }

    @Action({rawError: true})
    public async RemoveStandard(standard: Standard) {
        if (!standard.id) {
            throw new Error('Standard Id must be set to persist changes');
        }
        return await ApiHelpers.wrapCallAndPerformAction(
            async () => await ApiService.Delete(ApiRoutes.Admin.Standards.Delete(standard.id)) ,
            () => {
                this.removeStandard(standard);
            }
        );
    }

    @Action
    public async AddStandardBody(standardBody: StandardBody) {
        return await ApiHelpers.wrapCallAndSetValue<StandardBody>(
            async () => await ApiService.Post<StandardBody>(ApiRoutes.Admin.StandardBodies.Add, standardBody) ,
            (value) => {
                this.setStandardBody(value);
            }
        );
    }

    @Action
    public async UpdateStandardBody(standardBody: StandardBody) {
        if (!standardBody.id) {
            throw new Error('StandardBody Id must be set to persist changes');
        }
        return await ApiHelpers.wrapCallAndSetValue<StandardBody>(
            async () => await ApiService.Post<StandardBody>(ApiRoutes.Admin.StandardBodies.Edit(standardBody.id), standardBody) ,
            (value) => {
                this.setStandardBody(value);
            }
        );
    }

    @Action
    public async RemoveStandardBody(standardBody: StandardBody) {
        if (!standardBody.id) {
            throw new Error('StandardBody Id must be set to persist changes');
        }
        return await ApiHelpers.wrapCallAndPerformAction(
            async () => await ApiService.Delete(ApiRoutes.Admin.StandardBodies.Delete(standardBody.id)) ,
            () => {
                this.removeStandardBody(standardBody);
            }
        );
    }
}
