






import { Component, Vue, Prop } from 'vue-property-decorator';
import dictionary from '@/app.user/_common/Dictionary.json';
import { AuthStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';

import { ApiHelpers } from '@/_helpers';
import ApiService from '@/utility/ApiService';
import ApiRoutes from '@/_common/ApiRoutes';
import { BasicUserResponse } from '@/app.admin/models';
import { MbdGuidelinesStore } from '@/app.admin/store/modules';
import { PermissionsEnum } from '@/_common/permission.enums';
import { EventBus } from '@/app.admin/eventBus';

const guidelinesStore = getModule(MbdGuidelinesStore);
const authStore = getModule(AuthStore);

@Component
export default class LinkText extends Vue {
    @Prop({required: true}) public text!: string;
    @Prop({required: false}) public query!: string;

    get dictionaryCardAccess() {
        if (!authStore.TokenValues) {
            return false;
        }
        return authStore.TokenValues!.permissions.indexOf(PermissionsEnum[PermissionsEnum.DictionaryCardAccess]) !== -1;
    }

    get guidelines() {
        return guidelinesStore.GuidelinesArray;
    }

    get sortedTerms() {
        const terms = Array();
        // const guides = guidelinesStore.GuidelinesArray;

        // for (let i = 0; i < guides.length; i++) {
        //     terms.push({Name: guides[i].name, Definition: guides[i].ruleStatement,
        //         Link: '{{baseURL}}' + guides[i].id});
        // }
        for (const term in dictionary.Citations) {
            if (dictionary.Citations) {
                terms.push({Name: dictionary.Citations[term].ID, Definition: dictionary.Citations[term].Description, Link: 'http://dictionary.action-engineering.com/referencesview/' + dictionary.Citations[term].ID.replace(' ', '%20')});
            }
        }
        for (const term in dictionary.Entries) {
            if (dictionary.Entries) {
                if (dictionary.Entries[term].Other_Names !== '') {
                    for (let i = 0; i < dictionary.Entries[term].Other_Names.split(',').length; i++) {
                        terms.push({Name: dictionary.Entries[term].Other_Names.split(',')[i], Definition: dictionary.Entries[term].Definition, Link: 'https://dictionary.action-engineering.com/showdefinition/' + dictionary.Entries[term].Term.replaceAll(' ', '%20')});
                    }
                }
                terms.push({Name: dictionary.Entries[term].Term, Definition: dictionary.Entries[term].Definition, Link: 'https://dictionary.action-engineering.com/showdefinition/' + dictionary.Entries[term].Term.replaceAll(' ', '%20')});
            }
        }
        return terms.sort((a: any, b: any) => b.Name.length - a.Name.length);
    }

    // removes images from text so that text can be manipulated without ruining images
    private removeTags(text: string) {
        const arr = text.split('<');
        const imageArr = Array();
        let newText = text;
        // if arr is less than or equal to one, there are no images so return text as it is
        if (arr.length <= 1) {
            return [text, []];
        }
        // if arr is greater than one, every index after 0 starts with an image, so slice to first '>'
        // return array with new text with images replaced and imageArray with image texts
        if (arr.length > 1) {
            for (let i = 0; i < arr.length - 1; i++) {
                imageArr.push('<' + arr[i + 1].slice(0, arr[i + 1].indexOf('>')) + '>');
            }
            for (let i = 0; i < imageArr.length; i++) {
                newText = newText.replace(imageArr[i], '***' + [i] + '***');
            }
            for (let i = 0; i < imageArr.length; i++) {
                if (imageArr[i].substring(0, 4) === '<img') {
                    imageArr[i] = imageArr[i].substring(0, 4) + ' class = "imageText"' + imageArr[i].substring(3, imageArr[i].length - 1);
                }
            }
            return [newText, imageArr];
        }
    }

    // adds images back to text
    // takes in the text and an array of images
    private addTags(text: string, arr: any) {
        let newText = text;
        // for each item in image array, replace image string in the newText with the image at that index
        // return new text
        for (let i = 0; i < arr.length; i++) {
            newText = newText.replace('***' + [i] + '***', arr[i]);
            }
        return newText;
    }

    // takes in a text and returns text with linked terms
    private linked(text: string) {
        if (this.dictionaryCardAccess !== true) {
            return text;
        }
        // if there are no sorted terms just return text
        if (!this.sortedTerms) {
            return text;
        }
        // remove image html from text so it is not linked (index 0 is text and index 1 is an array of images)
        const imageArray = this.removeTags(text);
        const termArray = Array();
        // new text is text with images removed
        let newText = ' ' + imageArray![0].toString();
        let count = -1;
        // replace each term found in text with a link (no url yet to prevent recursion due to term names in urls)
        for (let i = 0; i < this.sortedTerms.length; i++) {
             // this is the term you are looking for
            let check = new RegExp('[^a-z]' + this.sortedTerms[i].Name + 's' + '[^a-z]', 'ig');
            // this is seaching for the term (matchedText) in the newTest and replace them with the link
            newText = newText.replace(check, (matchedText, a, b) => {
                count += 1;
                termArray.push([matchedText.substring(1, matchedText.length - 1), this.sortedTerms[i].Link]);
                return matchedText.charAt(0) + '<a class="linkedText" href="ThisWillBeALink' + count +
                    '" target="_blank" rel="noopener noreferrer">' +
                   'LinkedTerm' + count + '</a>' + matchedText.charAt(matchedText.length - 1);
            });
            check = new RegExp('[^a-z]' + this.sortedTerms[i].Name + '[^a-z]', 'ig');
            // this is seaching for the term (matchedText) in the newTest and replace them with the link
            newText = newText.replace(check, (matchedText, a, b) => {
                count += 1;
                termArray.push([matchedText.substring(1, matchedText.length - 1), this.sortedTerms[i].Link]);
                return matchedText.charAt(0) + '<a class="linkedText" href="ThisWillBeALink' + count +
                    '" target="_blank" rel="noopener noreferrer">' +
                    'LinkedTerm' + count + '</a>' + matchedText.charAt(matchedText.length - 1);
            });
        }

        newText = newText.substring(1, newText.length);
        // adds links to text
        for (let i = termArray.length; i >= 0; i--) {
            let check = 'ThisWillBeALink' + [i];
            newText = newText.replace(check, (matchedText, a, b) => {
                return termArray[i][1];
                }
            );
            check = 'LinkedTerm' + [i];
            newText = newText.replace(check, (matchedText, a, b) => {
                return termArray[i][0];
                }
            );
        }
        // add images back to text and return text
        const newerText = this.addTags(newText, imageArray![1]);
        return newerText;
    }

    // start links and cards
    private linkItems: any  = document.getElementsByClassName('linkedText');
    private dictionaryContainer: any = document.getElementsByClassName('popup-container');
    private dictionaryCard: any = document.getElementsByClassName('popup-card');
    // position of the mouse in the description div
    private mouseArray = Array(0, 0);
    // position of the mouse in the definition card
    private mouseCardArray = Array(0, 0);
    // position of the mouse in the document
    private mouseDocumentArray = Array(0, 0);
    private drag: boolean = false;

    private async addHoverListener() {
        await setTimeout(() => {
            if (this.dictionaryContainer[0] !== undefined) {
                this.dictionaryContainer[0].addEventListener('mouseout', (e: any) => {
                    let allTrue: number = this.linkItems.length + 1;
                    setTimeout(() => {
                        for (let j = 0; j < this.linkItems.length; j++) {
                            if (this.linkItems[j].matches(':hover') === false) {
                                allTrue -= 1;
                            }
                        }
                        if (this.dictionaryCard[0] !== undefined) {
                            if (this.dictionaryCard[0].matches(':hover') === false) {
                                allTrue -= 1;
                            }
                        }

                        if (allTrue === 0) {
                            this.dictionaryCard[0].classList.add('hidden');
                        }
                    }, 500);
                });
            }
            for (let i = 0; i < this.linkItems.length; i++) {
                this.linkItems[i].addEventListener('mouseover', (e: any) => {
                    EventBus.$emit('hoverName', this.linkItems[i].textContent.substring(0, 1).toUpperCase() +
                        this.linkItems[i].textContent.substring(1, this.linkItems[i].textContent.length));
                    if (this.dictionaryCard[0] !== undefined) {
                        const coords = this.getCoords(this.linkItems[i]);
                        this.dictionaryCard[0].style.left = (coords.left).toString() + 'px';
                        this.dictionaryCard[0].style.top = (coords.top - this.dictionaryCard[0].offsetHeight).toString() + 'px';
                        setTimeout(() => {
                            this.dictionaryCard[0].classList.remove('hidden');
                        }, 300);
                    }
                });
                this.linkItems[i].addEventListener('mouseout', (e: any) => {
                    let allTrue: number = this.linkItems.length + 1;
                    setTimeout(() => {
                        for (let j = 0; j < this.linkItems.length; j++) {
                            if (this.linkItems[j].matches(':hover') === false) {
                                allTrue -= 1;
                            }
                        }
                        if (this.dictionaryCard[0] !== undefined) {
                            if (this.dictionaryCard[0].matches(':hover') === false) {
                                allTrue -= 1;
                            }
                        }

                        if (allTrue === 0) {
                            this.dictionaryCard[0].classList.add('hidden');
                        }
                    }, 500);
                });
            }
            this.dictionaryCard[0].addEventListener('mouseout', (e: any) => {
                    let allTrue: number = this.linkItems.length + 1;
                    setTimeout(() => {
                        for (let j = 0; j < this.linkItems.length; j++) {
                            if (this.linkItems[j].matches(':hover') === false) {
                                allTrue -= 1;
                            }
                        }
                        if (this.dictionaryCard[0] !== undefined) {
                            if (this.dictionaryCard[0].matches(':hover') === false) {
                                allTrue -= 1;
                            }
                        }

                        if (allTrue === 0) {
                            this.dictionaryCard[0].classList.add('hidden');
                        }
                    }, 500);
                });
        }, 0);
    }

    private getCoords(elem: any) {
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top  = box.top +  scrollTop - clientTop;
    let left = box.left + scrollLeft - clientLeft;

    if ((screen.width - left) < 380) {
        left = left - (380 - (screen.width - left));
    }

    return { top: Math.round(top), left: Math.round(left) };
}

    // when x is clicked definition card is closed
    private closeHover() {
        this.dictionaryCard[0].classList.add('hidden');
    }
    // end links and cards

}
