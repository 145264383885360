



























































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { MbdGuideline } from '@/models/entities/mbdGuideline';
import MarkdownItVue from '@/components/MarkdownItVue.vue';
import { MbdGuidelinesStore } from '@/app.admin/store/modules';
import { getModule } from 'vuex-module-decorators';
import {LinkText} from '@/app.user/components/LinkTextAndPopUp';
import { EventBus } from '@/app.user/eventBus';

const guidelinesStore = getModule(MbdGuidelinesStore);

@Component({
    components: {
        MarkdownItVue,
        LinkText
    }
})

export default class MbdGuidelinesTile extends Vue {
    @Prop({required: true}) public guidelines!: MbdGuideline[];
    @Prop({required: true}) public guideline!: string;
    @Prop({required: true}) public categories!: any;
    @Prop({required: true}) public searchTerm!: string;

    private sortByCategory(guidelineCategory: string, category: string) {
        if (guidelineCategory === category || this.searchTerm.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    private isHtml(item: string) {
        return item[0] === '<';
    }

    private guidelineItems: any = document.getElementsByClassName('guideline-title-scroll-items');
    private scrollItem: any = document.getElementsByClassName('guideline-tile');

    private scrollToGuideline(id: string) {
        for (let i = 0; i < this.guidelineItems.length; i++) {
            if (this.guidelineItems[i].classList.contains('selected-guideline-tile')) {
                this.guidelineItems[i].classList.remove('selected-guideline-tile');
            }
            if (this.guidelineItems[i].id === id) {
                this.guidelineItems[i].classList.add('selected-guideline-tile');

                this.guidelineItems[i].scrollIntoView();
                window.scrollTo(0, 0);
            }
        }
    }

    // removes images from text so that text can be manipulated without ruining images
    private removeTags(text: string) {
        const arr = text.split('<');
        const imageArr = Array();
        let newText = text;
        // if arr is less than or equal to one, there are no images so return text as it is
        if (arr.length <= 1) {
            return [text, []];
        }
        // if arr is greater than one, every index after 0 starts with an image, so slice to first '>'
        // return array with new text with images replaced and imageArray with image texts
        if (arr.length > 1) {
            for (let i = 0; i < arr.length - 1; i++) {
                imageArr.push('<' + arr[i + 1].slice(0, arr[i + 1].indexOf('>')) + '>');
            }
            for (let i = 0; i < imageArr.length; i++) {
                newText = newText.replace(imageArr[i], '***' + [i] + '***');
            }
            return [newText, imageArr];
        }
    }

    // adds images back to text
    // takes in the text and an array of images
    private addTags(text: string, arr: any) {
        let newText = text;
        // for each item in image array, replace image string in the newText with the image at that index
        // return new text
        for (let i = 0; i < arr.length; i++) {
            newText = newText.replace('***' + [i] + '***', arr[i]);
            }
        return newText;
    }

    // highlights searched text
    private highlight(text: string) {
        // if no query return text
        if (!this.searchTerm) {
            return text;
        }
        // remove images from text
        const imageArray = this.removeTags(text);

        let newText = imageArray![0].toString();
        const check = new RegExp(this.searchTerm, 'ig');
        newText = newText.toString().replace(check, (matchedText, a, b) => {
            return '<span class="highlightText">' + matchedText + '</span>';
        });
        const newerText = this.addTags(newText, imageArray![1]);
        return newerText;
    }

    private routeToGuideline(guidelineId: string) {
        if (guidelineId !== '') {
            history.pushState({}, '', '/#/mbd-guidelines/' + decodeURIComponent(guidelineId));
            EventBus.$emit('change-guidelineId', guidelineId);
        } else if (guidelineId === '') {
            history.pushState({}, '', '/#/mbd-guidelines');
        }
    }

    @Watch('guideline')
    private onGuidelineChange(newValue: string, oldValue: string) {
        if (newValue === oldValue) {
            return;
        } else {
            if (newValue === '') {
                for (let i = 0; i < this.guidelineItems.length; i++) {
                    if (this.guidelineItems[i].classList.contains('selected-guideline-tile')) {
                        this.guidelineItems[i].classList.remove('selected-guideline-tile');
                    }
                }
                this.scrollItem[0].scrollTo(0, 0);
                window.scrollTo(0, 0);
            } else {
                this.scrollToGuideline(newValue);
            }
        }
    }
}
