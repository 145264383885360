import { SimpleEntity, CadSystemInstruction, CreateEditMdbGuidelineModel } from '..';

export class CreateEditGuidelineRequest {
    public id: string = '';
    public name: string;
    public category: SimpleEntity | null = null;

    public ruleStatement: string = '';
    public whyStatement: string = '';
    public agnosticInstructions: string = '';

    public contentAccessLevel: string = '';

    public instructions: CadSystemInstruction[] = [];

    constructor(name: string,
                category: SimpleEntity,
                ruleStatement: string,
                whyStatement: string,
                agnosticInstructions: string,
                instructions: CadSystemInstruction[],
                contentAccessLevel: string = '') {
        this.name = name;
        this.category = category;
        this.ruleStatement = ruleStatement;
        this.whyStatement = whyStatement;
        this.agnosticInstructions = agnosticInstructions;
        this.instructions = instructions;
        this.contentAccessLevel = contentAccessLevel;
    }

    /** Creates the Request from the CreateEdit Model */
    public static FromModel(model: CreateEditMdbGuidelineModel) {

        const output = new CreateEditGuidelineRequest(
            model.name,
            model.category!,
            model.ruleStatement,
            model.whyStatement,
            model.agnosticInstructions,
            model.instructions,
            model.contentAccessLevel
        );

        output.id = model.id;
        return output;
    }
}
