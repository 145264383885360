import { SimpleEntity, CadSystemInstruction, MbdGuidelineEditResponse } from '@/models';

export class CreateEditMdbGuidelineModel { // Cannot import from index due to circular dependency issues,  for some reason
    public id: string = '';
    public name: string = '';

    public category: SimpleEntity | null = null;
    public categoryId: string = '';

    public ruleStatement: string = '';
    public whyStatement: string = '';
    public agnosticInstructions: string = '';

    public contentAccessLevel: string = '';

    public instructions: CadSystemInstruction[] = [];

    public FromResponse(model: MbdGuidelineEditResponse) {
        this.id = model.id;
        this.name = model.name;
        this.category = model.category;

        this.ruleStatement = model.ruleStatement;
        this.whyStatement = model.whyStatement;
        this.agnosticInstructions = model.agnosticInstructions;

        this.contentAccessLevel = model.contentAccessLevel;

        this.instructions = model.instructions;
    }
}
