import Vue from 'vue';
import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';

import {FilesStore, PackagesStore, CoursesStore, MbdGuidelinesStore} from '.';
import { SearchStore } from '@/store/modules';
import store from '@/store/store';
import { SimpleFile, FileResponse, PackageResponse, SimplePackage, CourseResponse, MbdGuideline } from '@/models/entities';
import { parseVersions, filterEntities } from '@/_helpers';
import { EntityFilterParams } from '@/models/interfaces';

const searchStore = getModule(SearchStore);
const filesStore = getModule(FilesStore);
const packagesStore = getModule(PackagesStore);
const coursesStore = getModule(CoursesStore);
const guidelinesStore = getModule(MbdGuidelinesStore);

@Module({ namespaced: true, name: 'FilteredData', dynamic: true, store: store })
export default class FilteredDataStore extends VuexModule {


    //#region Files

    public _filteredFiles: FileResponse[] = [];
    public _filteredSimpleFiles: SimpleFile[] = [];

    get filteredFiles() {
        return this._filteredFiles;
    }

    get filteredSimpleFiles() {
        return this._filteredSimpleFiles;
    }

    @Mutation
    private setFilteredFiles(files: FileResponse[]) {
        this._filteredFiles = files;
    }

    @Mutation
    private setFilteredSimpleFiles(files: SimpleFile[]) {
        this._filteredSimpleFiles = files;
    }


    @Action
    public async GetFilteredFiles() {
        if (!searchStore.search && searchStore.tagsFilter.length === 0 && searchStore.propertiesFilter.length === 0) {
            this.setFilteredFiles(filesStore.files);
            return filesStore.files;
        }

        const filterArgs = searchStore.entityFilterParams;
        filterArgs.items = filesStore.files;

        const filtered = filterEntities(filterArgs);

        this.setFilteredFiles(filtered);
        return filtered;
    }

    // @Action
    // public async GetFilteredSimpleFilesPaginated() {
    //     if (!searchStore.search && searchStore.tagsFilter.length === 0) {
    //         this.setFilteredSimpleFiles(filesStore.paginatedFiles);
    //         return filesStore.filesList;
    //     }

    //     const filterArgs = searchStore.entityFilterParams;
    //     filterArgs.items = filesStore.filesList;

    //     const filtered = filterEntities(filterArgs);

    //     this.setFilteredSimpleFiles(filtered);
    //     return filtered;
    // }

    @Action
    public async GetFilteredSimpleFiles() {
        if (!searchStore.search && searchStore.tagsFilter.length === 0 && searchStore.propertiesFilter.length === 0) {
            this.setFilteredSimpleFiles(filesStore.filesList);
            return filesStore.filesList;
        }

        const filterArgs = searchStore.entityFilterParams;
        filterArgs.items = filesStore.filesList;

        const filtered = filterEntities(filterArgs);

        this.setFilteredSimpleFiles(filtered);
        return filtered;
    }

    //#endregion

    //#region Packages

    public _filteredPackages: PackageResponse[] = [];
    public _filteredSimplePackages: SimplePackage[] = [];

    get filteredPackages() {
        return this._filteredPackages;
    }

    get filteredSimplePackages() {
        return this._filteredSimplePackages;
    }

    @Mutation
    private setFilteredPackages(files: PackageResponse[]) {
        this._filteredPackages = files;
    }

    @Mutation
    private setFilteredSimplePackages(files: SimplePackage[]) {
        this._filteredSimplePackages = files;
    }


    @Action
    public async GetFilteredPackages() {
        if (!searchStore.search && searchStore.tagsFilter.length === 0 && searchStore.propertiesFilter.length === 0) {
            this.setFilteredPackages(packagesStore.Packages);
            return packagesStore.Packages;
        }

        const filterArgs = searchStore.entityFilterParams;
        filterArgs.items = packagesStore.Packages;

        const filtered = filterEntities(filterArgs);

        this.setFilteredPackages(filtered);
        return filtered;
    }

    @Action
    public async GetFilteredSimplePackages() {
        if (!searchStore.search && searchStore.tagsFilter.length === 0 && searchStore.propertiesFilter.length === 0) {
            this.setFilteredSimplePackages(packagesStore.PackagesList);
            return packagesStore.PackagesList;
        }

        const filterArgs = searchStore.entityFilterParams;
        filterArgs.items = packagesStore.PackagesList;

        const filtered = filterEntities(filterArgs);

        this.setFilteredSimplePackages(filtered);
        return filtered;
    }

    //#endregion

    //#region Courses

    public _filteredCourses: CourseResponse[] = [];

    get filteredCourses() {
        return this._filteredCourses;
    }

    @Mutation
    private setFilteredCourses(courses: CourseResponse[]) {
        this._filteredCourses = courses;
    }

    @Action
    public async GetFilteredCourses() {
        if (!searchStore.search && searchStore.tagsFilter.length === 0 && searchStore.propertiesFilter.length === 0) {
            this.setFilteredCourses(coursesStore.Courses);
            return coursesStore.Courses;
        }
        const filterArgs = searchStore.entityFilterParams;

        filterArgs.items = coursesStore.Courses;
        const filtered = filterEntities(filterArgs);
        this.setFilteredCourses(filtered);
        return filtered;
    }

    //#endregion

    //#region Guidelines

    public _filteredGuidelines: MbdGuideline[] = [];

    get filteredGuidelines() {
        return this._filteredGuidelines;
    }

    @Mutation
    private setFilteredGuidelines(guidelines: MbdGuideline[]) {
        this._filteredGuidelines = guidelines;
    }

    @Action
    public async GetFilteredGuidelines() {
        if (!searchStore.search && searchStore.tagsFilter.length === 0 && searchStore.propertiesFilter.length === 0) {
            this.setFilteredGuidelines(guidelinesStore.GuidelinesArray);
            return guidelinesStore.GuidelinesArray;
        }

        const filterArgs = searchStore.entityFilterParams;
        filterArgs.items = guidelinesStore.GuidelinesArray;

        const filtered = filterEntities(filterArgs);

        this.setFilteredGuidelines(filtered);
        return filtered;
    }

    //#endregion
}

