import { PropertyValue } from './propertyValue';
import { SelectionType } from '@/_common/enums';

export class Property {
    public id: string;
    public name: string;
    public standalone: boolean;
    public static: boolean;

    public requiredProperty: Property | null;
    public requiredPropertyId: string | null;

    public selectionType: string;
    public values: PropertyValue[];

    public hasRequiredProperty: boolean;

    constructor(id: string,
                name: string,
                standalone: boolean,
                selectionType: string,
                values: PropertyValue[],
                hasRequiredProperty: boolean,
                requiredProperty?: Property | null,
                requiredPropertyId?: string | null,
                isStatic: boolean = false) {
        this.id = id;
        this.name = name;
        this.standalone = standalone;
        this.requiredProperty = requiredProperty ? requiredProperty : null;
        this.requiredPropertyId = requiredPropertyId ? requiredPropertyId : null;
        this.selectionType = selectionType;
        this.values = values;
        this.hasRequiredProperty = hasRequiredProperty;
        this.static = isStatic;
    }

    get isMultiSelect() {
        return this.selectionType === SelectionType.MultiSelect;
    }

    get isSingleSelect() {
        return this.selectionType === SelectionType.SingleSelect;
    }
}
