import * as jwtJsDecode from 'jwt-js-decode';
import { BasicUserResponse } from '@/app.admin/models';

const TOKEN_KEY = 'oscar_token';

// Gets the JWT & Refresh Token as an Object
export const getTokenObj = (): TokenObj | null => {
  const tokenJson = window.localStorage.getItem(TOKEN_KEY);

  if (!tokenJson) {
    return null;
  }

  try {
    return JSON.parse(tokenJson);
  } catch { // Could not parse, destroy and return
    destroyToken();
    return null;
  }
};

// Gets the JWT itself
export const getToken = () => {
  const tokenJson = window.localStorage.getItem(TOKEN_KEY);

  if (!tokenJson) {
    return '';
  }
  const tokens: TokenObj = JSON.parse(tokenJson);
  return tokens.token;
};

export const saveToken = (token: TokenObj) => {
  const tokenJson = JSON.stringify(token);

  window.localStorage.setItem(TOKEN_KEY, tokenJson);
};

export const destroyToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export const getTokenPayload = (token: string): RawJwtValues => {
  return jwtJsDecode.jwtDecode(token).payload as RawJwtValues;
};

export default { getTokenObj, getToken, saveToken, destroyToken, getTokenPayload };
