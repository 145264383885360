// tslint:disable: variable-name

import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import store from '@/store/store';

// Global application states
@Module({ namespaced: true, name: 'State', dynamic: true, store: store })
export default class StateStore extends VuexModule {

    //#region Toast

    private _successToastMessage = '';
    private _successToast = false;
    private _successToastTimeout = 6000;

    get successToastMessage() {
        return this._successToastMessage;
    }

    get successToast() {
        return this._successToast;
    }

    get successToastTimeout() {
        return this._successToastTimeout;
    }

    @Mutation
    private setSuccessToastMessage(value: string) {
        this._successToastMessage = value;
    }

    @Mutation
    private toggleSuccessToast(value: boolean) {
        this._successToast = value;
    }

    @Mutation
    private setSuccessToastTimeout(value: number) {
        this._successToastTimeout = value;
    }

    @Mutation
    private setSuccessToast(data: {message: string, timeout: number}) {
        this._successToastTimeout = data.timeout;
        this._successToastMessage = data.message;
        this._successToast = true;
    }

    @Action
    public async SetSuccessToastMessage(value: string) {
        this.setSuccessToastMessage(value);
    }

    @Action
    public async SetSuccessToastTimeout(value: number) {
        this.setSuccessToastTimeout(value);
    }

    @Action
    public async ToggleSuccessToast(value: boolean) {
        this.toggleSuccessToast(value);
        if (!value) {
            this.setSuccessToastMessage('');
        }
    }

    @Action
    public async SetSuccessToast(data: {message: string, timeout: number}) {
        this.setSuccessToast(data);
    }

    //#endregion

    //#region Loading & Steps

    private _globalLoading = false; // Global loading state, unused atm
    private _loading: boolean = false;
    private _routeSteps: number = 0;
    private _routeStepsComplete: number = 0;
    private _prevPath: string = '';
    private _currentTab: number = 0;
    private _itemsPerPage: number = JSON.parse(localStorage.getItem('itemsPerPage') || '20');

    get loading() {
        return this._loading;
    }

    get routeSteps() {
        return this._routeSteps;
    }

    get routeStepsComplete() {
        return this._routeStepsComplete;
    }

    get prevPath() {
        return this._prevPath;
    }

    get currentTab() {
        return this._currentTab;
    }

    get itemsPerPage() {
        return this._itemsPerPage;
    }

    @Mutation
    public setPrevPath(value: string) {
        this._prevPath = value;
    }

    @Mutation
    public setCurrentTab(value: number) {
        this._currentTab = value;
    }

    @Mutation
    public setItemsPerPage(value: number) {
        this._itemsPerPage = value;
        localStorage.setItem('itemsPerPage', JSON.stringify(value));
    }

    @Mutation
    private setLoading(value: boolean) {
        this._loading = value;
    }

    @Mutation
    private setRouteSteps(value: number) {
        this._routeSteps = value;
    }

    @Mutation
    private setRouteStepsComplete(value: number) {
        this._routeStepsComplete = value;
    }

    @Action
    public async SetLoading(value: boolean) {
        if (value && this._routeSteps !== 0) {
            this.setRouteStepsComplete(0);
        }

        this.setLoading(value);
    }

    @Action
    public SetRouteSteps(value: number) {
        this.setRouteSteps(value);
    }

    @Action
    public AddRouteSteps(value: number) {
        this.setRouteSteps(this.routeSteps + value);
    }

    @Action
    public SetRouteStepsComplete(value: number) {
        this.setRouteStepsComplete(value);
    }

    @Action
    public IterateRouteStepsComplete() {
        this.setRouteStepsComplete(this._routeStepsComplete + 1);
    }

    //#endregion
}
