import Vue from 'vue';
import axios from 'axios';
import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';

import ApiService from '@/utility/ApiService';
import { ApiResponse } from '@/models/responses';

import store from '@/store/store';
import { CreatePackageRequest, SimplePackage, PackageResponse, Version } from '@/models/entities';
import { parseVersion, parseVersions, ApiHelpers } from '@/_helpers';

@Module({ namespaced: true, name: 'Packages', dynamic: true, store: store })
export default class PackagesStore extends VuexModule {

    private packagesList: SimplePackage[] = [];
    private packages: Record<string, PackageResponse> = {};

    get Package() {
        return (packageId: string) => {
            return this.packages[packageId];
        };
    }

    get Packages() {
        return Object.values(this.packages);
    }

    get PackagesList() {
        return this.packagesList;
    }

    @Mutation
    private SetList(items: SimplePackage[]) {
        this.packagesList = items;
    }

    @Mutation
    private SetPackages(files: PackageResponse[]) {
        files.forEach((item) => {
            Vue.set(this.packages, item.id, item);
        });
    }

    @Mutation
    private SetPackage(file: PackageResponse) {
        Vue.set(this.packages, file.id, file);
    }

    @Mutation
    private RemovePackage(packageId: string) {
        Vue.delete(this.packages, packageId);
        const index = this.packagesList.findIndex((x) => x.id === packageId);
        if (index !== -1) {
            Vue.delete(this.packagesList, index);
        }
    }


    @Action
    public async CreatePackage(newPackage: CreatePackageRequest): Promise<ApiResponse<any>> {
        return await ApiHelpers.wrapCall<any>(
            async () => await ApiService.Post<any>(ApiRoutes.Admin.Packages.CreatePackage, newPackage)
        );
    }

    @Action
    public async EditPackage(packageModel: CreatePackageRequest): Promise<ApiResponse<any>> {
        if (!packageModel.id) {
            throw Error('Cannot edit package because the package model has no Id');
        }

        return await ApiHelpers.wrapCall<any>(
            async () => await ApiService.Post<any>(ApiRoutes.Admin.Packages.EditPackage(packageModel.id), packageModel)
        );
    }

    @Action
    public async DeletePackage(packageId: string) {
        if (!packageId) {
            throw Error('Cannot delete package because the package model has no Id');
        }

        return await ApiHelpers.wrapCallAndPerformAction(
            async () => await ApiService.Delete(ApiRoutes.Admin.Packages.Delete(packageId)),
            () => {
                this.RemovePackage(packageId);
            }
        );
    }

    @Action // Fetches a single PackageResponse from the server
    public async fetchPackage(packageId: string) {
        return await ApiHelpers.wrapCallAndSetValue<PackageResponse>(
            async () => await ApiService.Get<PackageResponse>(ApiRoutes.Admin.Packages.GetById(packageId)) ,
            (value) => {
                parseVersion(value);
                this.SetPackage(value);
            }
        );
    }

    @Action
    public async fetchPackages() {
        return await ApiHelpers.wrapCallAndSetValue<PackageResponse[]>(
            async () => await ApiService.Get<PackageResponse[]>(ApiRoutes.Admin.Packages.GetAll) ,
            (values) => {
                parseVersions(values);
                this.SetPackages(values);
            }
        );
    }

    @Action // Fetches a list of SimplePackage[] from the server
    public async fetchList() {
        return await ApiHelpers.wrapCallAndSetValue<SimplePackage[]>(
            async () => await ApiService.Get<SimplePackage[]>(ApiRoutes.Admin.Packages.GetList) ,
            (values) => {
                parseVersions(values);
                this.SetList(values);
            }
        );
    }
}
