import { DirectiveOptions } from 'vue';
import { AuthStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';
import { PermissionsEnum, ContentAccessLevelsEnum, contentLevelToPermMap } from '@/_common/permission.enums';
import { indexOfByKey } from '@/utility/Utilities';

const authStore = getModule(AuthStore);

const hasPermDirective: DirectiveOptions = {
    inserted(el, binding, vnode) {
        let ok = false;
        let errorState = false;
        let errorMessage = '';
        const userPerms = authStore.TokenValues?.permissions;

        let permValues: string[] = []; // We will assume support for arrays of perms

        if (typeof(binding.value) === 'string') {
            permValues.push(binding.value);
        } else if (Array.isArray(binding.value)) {
            permValues = binding.value;
        } else {
            errorState = true;
            errorMessage = 'Provided directive value is invalid. Expected one of string or string[]';
        }

        if (!errorState) {
            for (let i = 0; i < permValues.length; i++) {
                const enumValue: PermissionsEnum = PermissionsEnum[permValues[i] as keyof typeof PermissionsEnum];
                if (!enumValue) {
                    errorState = true;
                    errorMessage = `Cannot find permission "${permValues[i]}" `;
                    break;
                }

                const permIndex = userPerms?.indexOf(permValues[i]);

                if (permIndex !== -1) {
                    ok = true;
                    break;
                }
            }
        }
        if (!ok || errorState) {
            if (binding.arg === 'disable') {
                el.setAttribute('style', 'cursor: not-allowed; pointer-events: none; opacity: 0.4;');
            } else {
                vnode.elm?.parentElement?.removeChild(vnode.elm);
            }
        }

        if (errorState) {
            throw new Error(errorMessage);
        }
    },
};

const hasContentAccessDirective: DirectiveOptions = {
    inserted(el, binding, vnode) {
        let ok = false;
        let errorState = false;
        let errorMessage = '';
        const userPerms = authStore.TokenValues?.permissions;

        let accessLevelNames: string[] = []; // We will assume support for arrays

        if (typeof(binding.value) === 'string') {
            accessLevelNames.push(binding.value);
        } else if (Array.isArray(binding.value)) {
            accessLevelNames = binding.value;
        } else {
            errorState = true;
            errorMessage = 'Provided directive value is invalid. Expected one of string or string[]';
        }

        if (accessLevelNames.includes('Free') && !accessLevelNames.includes('SubscriptionL1')) {
            accessLevelNames.push('SubscriptionL1');
        }
        if  (accessLevelNames.includes('SubscriptionL1') && !accessLevelNames.includes('Internal')) {
            accessLevelNames.push('Internal');
        }

        if (!errorState) {
            for (let i = 0; i < accessLevelNames.length; i++) {
                const contentEnum = ContentAccessLevelsEnum[accessLevelNames[i] as keyof typeof ContentAccessLevelsEnum];
                if (!contentEnum) {
                    errorState = true;
                    errorMessage = `Cannot find Content Access Level "${accessLevelNames[i]}" `;
                    break;
                }

                // Get associated Permission enum
                const permEnum = contentLevelToPermMap[contentEnum];

                if (!permEnum) {
                    errorState = true;
                    errorMessage = `Cannot find Permission associated with Content Access Level "${accessLevelNames[i]}" `;
                    break;
                }

                const permExists = userPerms?.indexOf(PermissionsEnum[permEnum].toString()) !== -1;

                if (permExists) {
                    ok = true;
                    break;
                }
            }
        }
        if (!ok || errorState) {
            if (binding.arg === 'disable') {
                el.setAttribute('style', 'cursor: not-allowed; pointer-events: none; opacity: 0.4;');
            } else {
                vnode.elm?.parentElement?.removeChild(vnode.elm);
            }
        }

        if (errorState) {
            throw new Error(errorMessage);
        }
    },
};

export {
    hasPermDirective,
    hasContentAccessDirective
};

