import Vue from 'vue';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import Http from '@/_helpers/http.helpers';
import ApiRoutes from '@/_common/ApiRoutes';
import { ErrorResponseType } from '@/_common/enums';
import { ErrorsResponse, AuthResponse, ApiResponse } from '@/models/responses';
import { JwtValues, FileUploadData } from '@/models';
import { AuthStore } from '@/store/modules';
import { AccountRegistrationRequest, CreateUserRequest } from '@/models/requests';

interface UploadProgress {
    total: number;
    loaded: number;
    speed: number;
}

// tslint:disable: no-console
export default class ApiService {

    private static jwtValidated: boolean = false;
    private static jwtValid: boolean = false;

    private static jwtRefreshCompleted: boolean = false;
    private static jwtRefreshFailed: boolean = false;

    private static requestQueue: Array<() => Promise<AxiosResponse<any>>> = [];
    private static authStore: AuthStore;

    public static SetAuthStore(authStore: AuthStore) {
        this.authStore = authStore;
    }

    public static async Get<TResult>(uri: string, params?: GetParams): Promise<ApiResponse<TResult>> {
        return await this.WrapApiCall<TResult>(async () => await Http.get(uri, params));
    }

    public static async Delete<TResult>(uri: string): Promise<ApiResponse<TResult>> {
        return await this.WrapApiCall<TResult>(async () => await Http.delete(uri));
    }


    public static async PostMultipart<TResult>(uri: string, data: any): Promise<ApiResponse<TResult>> {
        return await this.WrapApiCall<TResult>(async () => await Http.postMultipart(uri, data));
    }

    public static async Post<TResult>(uri: string, data: any): Promise<ApiResponse<TResult>> {
        return await this.WrapApiCall<TResult>(async () => await Http.post(uri, data));
    }

    public static async PostWithProgress<TResult>(
            uri: string,
            data: any,
            onProgress: (progressEvent: ProgressEvent) => void): Promise<ApiResponse<TResult>> {
        return await this.WrapApiCall<TResult>(async () => await Http.postWithProgress(uri, data, onProgress));
    }

    public static async Upload<TResult>(uri: string, uploadData: FileUploadData, cancelToken: CancelTokenSource)
        : Promise<ApiResponse<TResult>> {

        const formData = new FormData();
        formData.append('file', uploadData.file);

        const onProgress = (progressEvent: ProgressEvent) => {
            uploadData.uploaded = progressEvent.loaded;
        };

        return await this.WrapApiCall<TResult>(async () => await Http.upload(uri, formData, cancelToken, onProgress));
    }

    // Checks if the users session is valid by pinging a secured endpoint.
    // Relies on the token being set in localStorage first
    public static async ValidateSession(): Promise<boolean> {
        // Do not use WrapApiCall for this
        try {
            await Http.post(ApiRoutes.Global.Identity.Validate, {});
            this.jwtValidated = true;
            this.jwtValid = true;
            return true;
        } catch (errorResponse) {
            this.jwtValidated = true;
            this.jwtValid = false;
            return false;
        }
    }

    public static async RefreshSession(tokens: TokenObj): Promise<AuthResponse> {
        // Do not use WrapApiCall for this
        try {
            const response = await Http.post(ApiRoutes.Global.Identity.Refresh, tokens);

            this.jwtValid = true;
            this.jwtRefreshCompleted = true;
            return {
                token: response.data.token,
                refreshToken: response.data.refreshToken
            };
        } catch (errorResponse) {
            this.jwtValidated = true;
            this.jwtValid = false;
            this.jwtRefreshCompleted = true;
            this.jwtRefreshFailed = true;

            const errors: string[] = errorResponse.response.data.errors;
            return {
                errors: this.HandleGlobalError(errorResponse.response)
            };
        }
    }

    public static async Login(username: string, password: string): Promise<AuthResponse> {
        try {
            const response = await Http.post(ApiRoutes.Global.Identity.Login, {email: username, password: password});
            this.jwtValidated = true;
            this.jwtValid = true;
            return {
                token: response.data.token,
                refreshToken: response.data.refreshToken
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response)
            };
        }
    }

    public static async Register(model: AccountRegistrationRequest): Promise<AuthResponse> {
        try {
            const response = await Http.post(ApiRoutes.Global.Identity.Register, model);
            return {
                token: response.data.token,
                refreshToken: response.data.refreshToken
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response)
            };
        }
    }

    public static async CreateUser(model: CreateUserRequest): Promise<AuthResponse> {
        try {
            const response = await Http.post(ApiRoutes.Global.Identity.CreateUser, model);
            return {
                token: response.data.token,
                refreshToken: response.data.refreshToken
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response)
            };
        }
    }

    public static async EditUser(model: CreateUserRequest): Promise<AuthResponse> {
        try {
            const response = await Http.post(ApiRoutes.Global.Identity.EditUser, model);
            return {
                token: response.data.token,
                refreshToken: response.data.refreshToken
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response)
            };
        }
    }

    public static async SendEmailVerification(email: string): Promise<ApiResponse> {

        try {
            const response = await Http.post(ApiRoutes.Global.Identity.SendEmailVerification, {email: email});
            return {
                success: true
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }

    public static async ReSendEmailVerification(email: string): Promise<ApiResponse> {

        try {
            const response = await Http.post(ApiRoutes.Global.Identity.ReSendEmailVerification, {email: email});
            return {
                success: true
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }

    public static async SendAssessment(
        username: string, email: string, assessment: string, score: string, percent: string, bonus: string, answers: string): Promise<ApiResponse> {

        try {
            const response = await Http.post(ApiRoutes.Global.Identity.SendAssessment,
                {username: username, email: email, assessment: assessment, score: score, percent: percent, bonus: bonus, answers: answers});
            return {
                success: true
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }

    public static async RequestPasswordReset(email: string): Promise<ApiResponse> {

        try {
            const response = await Http.post(ApiRoutes.Global.Identity.RequestPasswordReset, {email: email});
            return {
                success: true
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }

    public static async SendDowngradeUserEmail(email: string, sublevel: string): Promise<ApiResponse> {

        try {
            const response = await Http.post(ApiRoutes.Global.Identity.SendDowngradeUserEmail, {email: email, sublevel: sublevel});
            return {
                success: true
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }

    public static async ResetPassword(userId: string, token: string, newPassword: string): Promise<ApiResponse> {

        try {
            const response = await Http.post(ApiRoutes.Global.Identity.ResetPassword, {userId: userId, token: token, newPassword: newPassword});
            return {
                success: true
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }

    public static async SetPassword(userId: string, password: string): Promise<ApiResponse> {
        try {
            const response = await Http.post(ApiRoutes.Global.Identity.VerifyEmailAndSetupAccount,
                {userId: userId, password: password});
            return {
                success: true
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }

    public static async ConfirmEmail(userId: string, token: string): Promise<ApiResponse> {
        try {
            const response = await Http.post(ApiRoutes.Global.Identity.ConfirmEmail,
                {userId: userId, token: token});
            return {
                success: true
            };
        } catch (errorResponse) {
            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }

    // Handles global API errors
    // Any error that is returned with a non-200 status code
    public static HandleGlobalError(response: AxiosResponse): ErrorsResponse {
        // Offline, can't connect to server
        if (response === undefined) {
            return  {
                type: ErrorResponseType.Offline,
                errors: [{message: 'Unable to connect to server'}]
            };
        }
        if (response.status === 401) {
            // Do something with the unauthorized error like attempting to refresh session?
            return  {
                type: ErrorResponseType.Unauthorized,
                errors: [{message: 'Unauthorized, please refresh the page'}]
            };
        }

        if (response.status === 403) {
            if (response.data) {
                return {
                    type: ErrorResponseType.Unauthorized,
                    errors: [response.data]
                };
            }

            return  {
                type: ErrorResponseType.Unauthorized,
                errors: [{message: 'Not authorized to access this resource'}]
            };
        }

        if (response.status === 404) {
            if (response.data) {
                return  {
                    type: ErrorResponseType.NotFound,
                    errors: [response.data]
                };
            } else {
                return  {
                    type: ErrorResponseType.NotFound,
                    errors: [{message: 'Not Found'}]
                };
            }

        }

        if (response.status === 500) {
            return  {
                type: ErrorResponseType.Fatal,
                errors: [{message: 'An Unexpected Failure Has Occurred. Please try again later.'}]
            };
        }

        // Error without the standard errors response
        if (response.data === undefined) {
            console.error('Unknown Error Response, Data is Undefined', response);
            throw new Error('Unknown Error Response Type, Data is Undefined');
        }

        // Response is one of the recognized error types
        if (response.data.type in ErrorResponseType) {
            return response.data as ErrorsResponse;
        }

        console.error('Unknown Error Response Type', response);
        throw new Error('Unknown Error Response Type');
    }

    private static ShouldDelayCall() {
        if (!this.jwtValidated) { return true; } // JWT Validation Not Done
        if (!this.jwtValid ) { return true; } // JWT Validation done, JWT invalid
        if (!this.jwtRefreshCompleted && !this.jwtValid) { return true; } // Refresh attempt has not been made yet, JWT is currently invalid

        // JWT is valid, OR Validation complete and JWT is invalid
        // Next step should determine if calls should be cancelled
        return false;
    }

    private static ShouldCancelCalls() {
        if (this.jwtValidated && this.jwtValid) { return false; } // JWT valid, continue
        if (this.jwtRefreshFailed) { return true; } // Refresh failed, user is logged out
        return false;
    }

    private static async DelayCall() {
        return new Promise((r) => setTimeout(r, 10));
    }

    private static async WrapApiCall<TResult>(httpCall: () => Promise<AxiosResponse<any>>): Promise<ApiResponse<TResult>> {
        await this.authStore.LocalSessionCheck(); // Local check before continuing

        while (this.ShouldDelayCall()) {
            await this.DelayCall();
        }

        if (this.ShouldCancelCalls()) {
            return Promise.reject('Cancelling API calls, session invalid');
        }

        try {
            const response = await httpCall();
            return {
                result: response.data,
                success: true
            };
        } catch (errorResponse) {
            console.group('API Call Error');
            console.error('An API call error has occurred');
            console.error(errorResponse);
            console.error(errorResponse.response);
            console.groupEnd();

            return {
                errors: this.HandleGlobalError(errorResponse.response),
                success: false
            };
        }
    }
}
