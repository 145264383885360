import Vue from 'vue';
import axios from 'axios';
import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import { StandardBody, Standard, FileSystemItem } from '@/models/entities';
import ApiService from '@/utility/ApiService';

import store from '@/store/store';
import { ApiHelpers } from '@/_helpers';
import { arrayToHashByProp, arrayPropsToHashByProp, generateId } from '@/utility/Utilities';
import { Folder } from '@/models/entities/fileSystem/folder';
import { ItemListEditBase } from '@/app.admin/components';

@Module({ namespaced: true, name: 'FileSystem', dynamic: true, store: store })
export default class FileSystemStore extends VuexModule {
    private fileSystem: FileSystemItem[] = [];
    private folderOnlyFileSystem: FileSystemItem[] = [];
    private flattenedFileSystem: Record<string, FileSystemItem> = {}; // Used to find specific items by key (path)

    get FileSystem() {
        return this.fileSystem;
    }

    get RootFolders() {
        return Object.values(this.fileSystem);
    }

    get FolderOnlyRoot() {
        return this.folderOnlyFileSystem;
    }


    @Mutation
    private setRootFolders(items: FileSystemItem[]) {
        this.fileSystem = items;
    }

    @Mutation
    private setKeyedItems(items: Record<string, FileSystemItem>) {
        Object.keys(items).forEach((key) => {
            Vue.set(this.flattenedFileSystem, key, items[key]);
        });
    }

    @Mutation
    private setFolderChildren(data: {folder: FileSystemItem, children?: FileSystemItem[]}) {
        data.folder.children = data.children;
    }

    @Mutation
    private setRootFolderOnlyFolders(items: FileSystemItem[]) {
        this.folderOnlyFileSystem = items;
    }

    @Mutation
    private addDirectoryToFolder(data: {parent: FileSystemItem, child: FileSystemItem}) {
        Vue.set(data.parent.children!, data.parent.children!.length, data.child);
    }

    @Mutation
    private setItemName(data: { item: FileSystemItem, name: string}) {
        data.item.name = data.name;
    }

    @Mutation
    private moveItem(data: { item: FileSystemItem, destination: FileSystemItem}) {
        if (data.item.parent) {
            const parentChildIndex = data.item.parent.children!.findIndex((x) => x.path === data.item.path);
            if (parentChildIndex === -1) {
                throw new Error('Invalid State: Item does not exist in parents children');
            }
            data.item.parent.children!.splice(parentChildIndex, 1);
            data.destination.children!.push(data.item);
        } else {
            const parentChildIndex = this.fileSystem!.findIndex((x) => x.path === data.item.path);
            if (parentChildIndex === -1) {
                throw new Error('Invalid State: Item does not exist in parents children');
            }
            this.fileSystem.splice(parentChildIndex, 1);
            data.destination.children!.push(data.item);
        }
    }

    @Mutation
    private addRootDirectory(dir: FileSystemItem) {
        Vue.set(this.fileSystem, dir.id, dir);
        Vue.set(this.folderOnlyFileSystem, this.folderOnlyFileSystem.length, dir);
    }

    @Action
    public async fetchRootFolders() {
        return await ApiHelpers.wrapCallAndSetValue<FileSystemItem[]>(
            async () => await ApiService.Get<FileSystemItem[]>(ApiRoutes.Admin.FileSystem.GetRoot) ,
            (values) => {
                this.setKeyedItems(arrayToHashByProp(values, 'path'));
                this.setRootFolders(values);
                this.setRootFolderOnlyFolders(values.filter((x) => x.type === 'folder'));
            }
        );
    }

    @Action({rawError: true})
    public async fetchFolder(folder: FileSystemItem) {
        return await ApiHelpers.wrapCallAndSetValue<FileSystemItem>(
            async () => await ApiService.Get<FileSystemItem>(ApiRoutes.Admin.FileSystem.GetFolder(folder.id)),
            (value) => {
                value.children?.forEach((item) => item.parent = folder);
                if (value.children) {
                    this.setKeyedItems(arrayToHashByProp(value.children, 'path'));
                }

                this.setFolderChildren({folder: folder, children: value.children});
            }
        );
    }

    @Action({rawError: true})
    public async fetchFolderOnlyFolder(folder: FileSystemItem) {
        return await ApiHelpers.wrapCallAndSetValue<FileSystemItem>(
            async () => await ApiService.Get<FileSystemItem>(ApiRoutes.Admin.FileSystem.GetFolderFolders(folder.id)),
            (value) => {
                value.children?.forEach((item) => item.parent = folder);
                this.setFolderChildren({folder: folder, children: value.children});
            }
        );
    }

    @Action
    public async RenameFolder(data: {folder: FileSystemItem, name: string}) {
        const requestData = {id: data.folder.id, name: data.name};

        return await ApiHelpers.wrapCallAndSetValue<FileSystemItem>(
            async () => await ApiService.Post<FileSystemItem>(ApiRoutes.Admin.FileSystem.RenameFolder(data.folder.id), requestData),
            (value) => {
                value.children?.forEach((item) => item.parent = data.folder);
                if (value.children) {
                    this.setKeyedItems(arrayToHashByProp(value.children, 'path'));
                }
                this.setItemName({item: data.folder, name: value.name});
                this.setFolderChildren({folder: data.folder, children: value.children});
            }
        );
    }


    @Action({rawError: true})
    public async RenameFile(data: {file: FileSystemItem, name: string}) {
        const requestData = {id: data.file.id, name: data.name};

        return await ApiHelpers.wrapCallAndPerformAction(
            async () => await ApiService.Post(ApiRoutes.Admin.Files.RenameFile(data.file.id), requestData),
            () => {
                this.setItemName({item: data.file, name: data.name});
            }
        );
    }

    @Action({rawError: true}) // TODO: handle moving to root
    public async MoveFile(data: {item: FileSystemItem, destination: FileSystemItem}) {
        const requestData = {id: data.item.id, destinationId: data.destination.id};

        return await ApiHelpers.wrapCallAndPerformAsyncAction(
            async () => await ApiService.Post(ApiRoutes.Admin.Files.MoveFile(data.item.id), requestData),
            async () => {
                // Reload state of new parentFolder, and previous folder to keep client state synced
                await this.fetchFolder(data.destination);
                if (data.item.parent) {
                    await this.fetchFolder(data.item.parent);
                } else {
                    await this.fetchRootFolders();
                }
            }
        );
    }

    @Action({rawError: true}) // TODO: handle moving to root
    public async MoveFolder(data: {item: FileSystemItem, destination: FileSystemItem}) {
        const requestData = {id: data.item.id, destinationId: data.destination.id};

        return await ApiHelpers.wrapCallAndPerformAsyncAction(
            async () => await ApiService.Post(ApiRoutes.Admin.FileSystem.MoveFolder(data.item.id), requestData),
            async () => {
                // Reload state of new parentFolder, and previous folder to keep client state synced
                await this.fetchFolder(data.destination);
                if (data.item.parent) {
                    await this.fetchFolder(data.item.parent);
                } else {
                    await this.fetchRootFolders();
                }
            }
        );
    }

    @Action({rawError: true})
    public async AddDirectory(data: {parent: FileSystemItem, name: string}) {
        const requestData = {parentFolderId: data.parent.id, name: data.name};

        return await ApiHelpers.wrapCallAndSetValue<FileSystemItem>(
            async () => await ApiService.Post<FileSystemItem>(ApiRoutes.Admin.FileSystem.CreateFolder(data.parent.id), requestData),
            (value) => {
                this.addDirectoryToFolder({
                    parent: data.parent,
                    child: value
                });
            }
        );
    }

    @Action({rawError: true})
    public async AddRootDirectory(name: string) {
        const requestData = {parentFolderId: null, name: name};

        return await ApiHelpers.wrapCallAndSetValue<FileSystemItem>(
            async () => await ApiService.Post<FileSystemItem>(ApiRoutes.Admin.FileSystem.CreateRootFolder, requestData),
            (value) => {
                this.addRootDirectory(value);
            }
        );
    }
}
