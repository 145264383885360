import Vue from 'vue';
import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import ApiService from '@/utility/ApiService';

import store from '@/store/store';
import { CourseResponse, CreateEditCourseRequest } from '@/models/entities';
import { ApiHelpers } from '@/_helpers';

@Module({ namespaced: true, name: 'Courses', dynamic: true, store: store })
export default class CoursesStore extends VuexModule {

    private courses: Record<string, CourseResponse> = {};

    get Course() {
        return (courseId: string) => {
            return this.courses[courseId];
        };
    }

    get Courses() {
        return Object.values(this.courses);
    }

    @Mutation
    private setCourses(items: CourseResponse[]) {
        items.forEach((item) => {
            Vue.set(this.courses, item.id, item);
        });
    }

    @Mutation
    private setCourse(item: CourseResponse) {
        Vue.set(this.courses, item.id, item);
    }

    @Mutation
    private removeCourse(id: string) {
        Vue.delete(this.courses, id);
    }


    @Action({rawError: true})
    public async CreateCourse(newCourse: CreateEditCourseRequest) {
        return await ApiHelpers.wrapCallAndSetValue<CourseResponse>(
            async () => await ApiService.Post<CourseResponse>(ApiRoutes.Admin.Course.Create, newCourse),
            (value) => {
                this.setCourse(value);
            }
        );
    }

    @Action({rawError: true})
    public async EditCourse(courseModel: CreateEditCourseRequest) {
        if (!courseModel.id) {
            throw Error('Cannot edit course because the course model has no Id');
        }

        return await ApiHelpers.wrapCallAndSetValue<CourseResponse>(
            async () => await ApiService.Post<CourseResponse>(ApiRoutes.Admin.Course.Edit(courseModel.id), courseModel),
            (value) => {
                this.setCourse(value);
            }
        );
    }

    @Action({rawError: true})
    public async RemoveCourse(course: CourseResponse) {
        return await ApiHelpers.wrapCallAndPerformAction(
            async () => await ApiService.Delete(ApiRoutes.Admin.Course.Delete(course.id)),
            () => {
                this.removeCourse(course.id);
            }
        );
    }

    @Action({rawError: true}) // Fetches a single PackageResponse from the server
    public async fetchCourse(courseId: string) {
        return await ApiHelpers.wrapCallAndSetValue<CourseResponse>(
            async () => await ApiService.Get<CourseResponse>(ApiRoutes.Admin.Course.GetById(courseId)) ,
            (value) => {
                this.setCourse(value);
            }
        );
    }

    @Action({rawError: true})
    public async fetchCourses() {
        return await ApiHelpers.wrapCallAndSetValue<CourseResponse[]>(
            async () => await ApiService.Get<CourseResponse[]>(ApiRoutes.Admin.Course.GetAll) ,
            (values) => {
                this.setCourses(values);
            }
        );
    }
}
