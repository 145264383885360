import { PropertyValuePairIds, CreateEditMetadataModel } from '..';

// The translated model for editing metadata
// Or if the model has not been filled in from a response, for creating metadata
export class CreateEditMetadataRequest {

    public id: string = '';
    public tagIds: string[];

    public propertyValueIds: string[];
    public pairedProperties: PropertyValuePairIds[];

    public standardIds: string[];
    public mbdGuidelineIds: string[];

    constructor(
        standards: string[],
        mbdGuidelines: string[],
        tagIds: string[] = [],
        propertyValueIds: string[] = [],
        pairedProperties: PropertyValuePairIds[] = []) {
        this.standardIds = standards;
        this.mbdGuidelineIds = mbdGuidelines;

        this.tagIds = tagIds;
        this.propertyValueIds = propertyValueIds;
        this.pairedProperties = pairedProperties;
    }

    /** Creates the Request from the CreateEdit Model */
    public static FromModel(model: CreateEditMetadataModel) {
        return new CreateEditMetadataRequest(
            model.selectedStandards,
            model.selectedMbdGuidelines,
            model.tagIds,
            model.selectedProperties.propertyValueIds,
            model.selectedProperties.pairedPropertyValueIds);
    }
}
