import { CreateEditMetadataRequest } from '../metadata';
import { CreateEditVimeoVideoModel } from './CreateEditVimeoVideoModel';

export class CreateEditVimeoVideoRequest {
    public id: string = '';

    public vimeoId: number;

    public name: string;
    public description: string; // Short Description
    public notes: string; // Rich text
    public metadata: CreateEditMetadataRequest;

    public index: number;

    constructor(name: string, description: string, notes: string, index: number, vimeoId: number, metadata: CreateEditMetadataRequest) {
        this.name = name;
        this.description = description;
        this.notes = notes;
        this.index = index;
        this.vimeoId = vimeoId;
        this.metadata = metadata;
    }

    /** Creates the Request from the CreateEdit Model */
    public static FromModel(model: CreateEditVimeoVideoModel) {
        const output = new CreateEditVimeoVideoRequest(
            model.name,
            model.description,
            model.notes,
            model.index,
            model.vimeoId,
            CreateEditMetadataRequest.FromModel(model.metadata));
        output.id = model.id;
        return output;
    }
}
