import Vue from 'vue';
import axios from 'axios';
import ApiRoutes from '@/_common/ApiRoutes';
import { Module, VuexModule, Mutation, Action, MutationAction} from 'vuex-module-decorators';
import { MbdGuideline, SimpleEntity, CreateEditGuidelineRequest } from '@/models/entities';
import ApiService from '@/utility/ApiService';

import store from '@/store/store';
import { ApiHelpers } from '@/_helpers';
import { arrayToHashByProp } from '@/utility/Utilities';
import { ApiResponse, CadSystemInstruction } from '@/models';
import { AggregateChangeRequest } from '@/models/requests';

@Module({ namespaced: true, name: 'MbdGuidelines', dynamic: true, store: store })
export default class MbdGuidelinesStore extends VuexModule {
    private guidelines: Record<string, MbdGuideline> = {};
    private categories: Record<string, SimpleEntity> = {};

    get GuidelinesArray(): MbdGuideline[] {
        return Object.values(this.guidelines);
    }

    get KeyedGuidelines(): Record<string, MbdGuideline> {
        return this.guidelines;
    }

    get Guideline() {
        return (key: string): MbdGuideline => {
            return this.guidelines[key];
        };
    }

    get CategoriesArray(): SimpleEntity[] {
        return Object.values(this.categories);
    }

    get KeyedCategories(): Record<string, SimpleEntity> {
        return this.categories;
    }

    get Category() {
        return (key: string): SimpleEntity => {
            return this.categories[key];
        };
    }

    /*get InstructionsArray(): CadSystemInstructions[] {
        return Object.values(this.instructions);
    }

    get KeyedInstructions(): Record<string, CadSystemInstructions> {
        return this.instructions;
    }

    get Instruction() {
        return (key: string): CadSystemInstructions => {
            return this.instructions[key];
        };
    }*/


    @Mutation
    private setGuidelines(guidelines: Record<string, MbdGuideline>) {
        this.guidelines = guidelines;
    }

    @Mutation
    private setGuidelineInstructions(instructions: CadSystemInstruction[], guidelineId: string) {
        if (this.guidelines[guidelineId] === undefined) {
            throw new Error('Cannot set instructions for Guideline ' + guidelineId + ' Guideline not in store');
        }
        this.guidelines[guidelineId].instructions = instructions;
    }

    @Mutation
    private setGuideline(guideline: MbdGuideline) {
        this.guidelines[guideline.id] = guideline;
    }

    @Mutation
    private setCategories(categories: Record<string, SimpleEntity>) {
        this.categories = categories;
    }

    @Mutation
    private setInstruction(guideline: MbdGuideline) {
        this.guidelines[guideline.id] = guideline;
    }

    @Mutation
    private removeGuideline(guideline: MbdGuideline) {
        Vue.delete(this.guidelines, guideline.id);
    }

    @Action
    public async fetchGuideline(id: string) {
        return await ApiHelpers.wrapCallAndSetValue<MbdGuideline>(
        async () => await ApiService.Get<MbdGuideline>(ApiRoutes.Admin.MbdGuideline.GetById(id)) ,
            (value) => {
                this.setGuideline(value);
            }
        );
    }

    @Action
    public async fetchGuidelines() {
        return await ApiHelpers.wrapCallAndSetValue<MbdGuideline[]>(
        async () => await ApiService.Get<MbdGuideline[]>(ApiRoutes.User.MbdGuideline.GetAllGuidelines) ,
            (values) => {
                this.setGuidelines(arrayToHashByProp(values, 'id'));
            }
        );
    }

    @Action
    public async fetchInstructionsForGuideline(guidelineId: string) {
        return await ApiHelpers.wrapCallAndSetValue<CadSystemInstruction[]>(
        async () => await ApiService.Get<CadSystemInstruction[]>(ApiRoutes.Admin.MbdGuideline.GetInstructionsByGuideline(guidelineId)) ,
            (values) => {
                this.setGuidelineInstructions(values, guidelineId);
            }
        );
    }
    /*@Action
    public async fetchGuidelineList() {
        return await ApiHelpers.wrapCallAndSetValue<MbdGuideline>(
        async () => await ApiService.Get<MbdGuideline>(ApiRoutes.Admin.MbdGuideline.GetList) ,
            (value) => {
                this.setGuideline(value);
            }
        );
    }*/

    @Action
    public async fetchCategories() {
        return await ApiHelpers.wrapCallAndSetValue<SimpleEntity[]>(
            async () => await ApiService.Get<SimpleEntity[]>(ApiRoutes.Admin.MbdGuideline.GetAllCategories) ,
            (values) => {
                this.setCategories(arrayToHashByProp(values, 'id'));
            }
        );
    }

    @Action
    public async createGuideline(newGuideline: CreateEditGuidelineRequest): Promise<ApiResponse<any>> {
        return await ApiHelpers.wrapCall<any>(
            async () => await ApiService.Post<any>(ApiRoutes.Admin.MbdGuideline.CreateGuideline, newGuideline)
        );
    }

    @Action
    public async editGuideline(guideline: CreateEditGuidelineRequest): Promise<ApiResponse<any>> {
        if (!guideline.id) {
            throw Error('Cannot edit guideline because the guideline model has no Id');
        }

        return await ApiHelpers.wrapCall<any>(
            async () => await ApiService.Post<any>(ApiRoutes.Admin.MbdGuideline.EditGuideline(guideline.id), guideline)
        );
    }

    @Action
    public async SubmitAggregateCategoryChanges(changes: AggregateChangeRequest<SimpleEntity>): Promise<ApiResponse<any>> {
        return await ApiHelpers.wrapCall<void>(
            async () => await ApiService.Post<void>(ApiRoutes.Admin.MbdGuideline.SubmitAggregateChanges, changes)
        );
    }

    @Action
    public async RemoveGuideline(guideline: MbdGuideline) {
        if (!guideline.id) {
            throw new Error('Guideline Id must be set to persist changes');
        }
        return await ApiHelpers.wrapCallAndPerformAction(
            async () => await ApiService.Delete(ApiRoutes.Admin.MbdGuideline.Delete(guideline.id)) ,
            () => {
                this.removeGuideline(guideline);
            }
        );
    }
}
