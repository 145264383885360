import { AggregateChangeRequest, SimpleEntityChangeSetRequest } from '@/models/requests';
import { SimpleEntity } from '../simpleEntity';

export class CreateEditPropertyRequest {
    public id: string;
    public name: string;
    public selectionType: string;
    public standalone: boolean;

    public values?: AggregateChangeRequest<SimpleEntity>;

    constructor(id: string, name: string, selectionType: string, standalone: boolean, values?: AggregateChangeRequest<SimpleEntity>) {
        this.id = id;
        this.name = name;
        this.selectionType = selectionType;
        this.standalone = standalone;
        this.values = values;
    }
}

// tslint:disable-next-line: max-classes-per-file
export class CreateEditPairedPropertyRequest {
    public id: string;
    public name: string;
    public selectionType: string;
    public standalone: boolean;

    public requiredProperty: CreateEditPropertyRequest;

    public values: AggregateChangeRequest<SimpleEntityChangeSetRequest>;

    constructor(id: string,
                name: string,
                selectionType: string,
                standalone: boolean,
                requiredProperty: CreateEditPropertyRequest,
                values: AggregateChangeRequest<SimpleEntityChangeSetRequest>) {
        this.id = id;
        this.name = name;
        this.selectionType = selectionType;
        this.standalone = standalone;
        this.requiredProperty = requiredProperty;
        this.values = values;
    }
}
