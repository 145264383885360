







































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DocumentCard extends Vue {
    @Prop({required: true, type: String}) public cardType!: string; // document, or video

    private cardClicked(type: string) {
        this.$emit('clicked');
    }
}
