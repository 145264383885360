import store from '@/store/store';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import JwtService from '@/utility/JwtService';
import ApiService from '@/utility/ApiService';
import { JwtValues, ErrorResponse } from '@/models';
import { ErrorResponseType } from '@/_common/enums';
import { AccountRegistrationRequest } from '@/models/requests';
import { indexOfByKey } from '@/utility/Utilities';
import { ApiHelpers } from '@/_helpers';
import ApiRoutes from '@/_common/ApiRoutes';
import { ContentLevelResponse } from '@/models/permissions';

@Module({ namespaced: true, name: 'Global', dynamic: true, store: store })
export default class GlobalDataStore extends VuexModule {
    private contentAccessLevels: ContentLevelResponse[] = [];

    get ContentAccessLevels() {
        return this.contentAccessLevels;
    }

    get ContentAccessLevel() {
        return (level: number) => {
            const index = indexOfByKey(this.contentAccessLevels, 'level', level);
            if (index === -1) {
                throw new Error(`Content access level for level ${level} not found`);
            }
        };
    }

    // Used only for internal state management
    @Mutation
    private setContentAccessValues(values: ContentLevelResponse[]) {
        this.contentAccessLevels = values;
    }

    @Action
    public async FetchContentAccessValues() {
        return await ApiHelpers.wrapCallAndSetValue<ContentLevelResponse[]>(async () =>
            await ApiService.Get<ContentLevelResponse[]>(ApiRoutes.Admin.MiscData.GetContentAccessLevels),
            (values) => {
                this.setContentAccessValues(values);
            }
        );
    }
}
