





















import { Component, Vue, Prop } from 'vue-property-decorator';
import dictionary from '@/app.user/_common/Dictionary.json';
import { MbdGuidelinesStore } from '@/app.admin/store/modules';
import { getModule } from 'vuex-module-decorators';
import { EventBus } from '@/app.admin/eventBus';

const guidelinesStore = getModule(MbdGuidelinesStore);

@Component
export default class PopUpCard extends Vue {

    private dictionaryCard: any = document.getElementsByClassName('popup-card');
    private term: string = '';
    get guidelines() {
        return guidelinesStore.GuidelinesArray;
    }

    get sortedTerms() {
        const terms = Array();
        // const guides = guidelinesStore.GuidelinesArray;

        // for (let i = 0; i < guides.length; i++) {
        //     terms.push({Name: guides[i].name, Definition: guides[i].ruleStatement,
        //         Link: '{{baseURL}}' + guides[i].id});
        // }

        for (const term in dictionary.Citations) {
            if (dictionary.Citations) {
                terms.push({Name: dictionary.Citations[term].ID, Definition: dictionary.Citations[term].Description, Link: 'http://dictionary.action-engineering.com/referencesview/' + dictionary.Citations[term].ID.replace(' ', '%20')});
            }
        }
        for (const term in dictionary.Entries) {
            if (dictionary.Entries) {
                if (dictionary.Entries[term].Other_Names !== '') {
                    for (let i = 0; i < dictionary.Entries[term].Other_Names.split(',').length; i++) {
                        terms.push({Name: dictionary.Entries[term].Other_Names.split(',')[i], Definition: dictionary.Entries[term].Definition, Link: 'https://dictionary.action-engineering.com/showdefinition/' + dictionary.Entries[term].Term.replaceAll(' ', '%20')});
                    }
                }
                terms.push({Name: dictionary.Entries[term].Term, Definition: dictionary.Entries[term].Definition, Link: 'https://dictionary.action-engineering.com/showdefinition/' + dictionary.Entries[term].Term.replaceAll(' ', '%20')});
            }
        }
        return terms.sort((a: any, b: any) => b.Name.length - a.Name.length);
    }

    get name() {
        for (let i = 0; i < this.sortedTerms.length; i++) {
            if (this.term.toLowerCase() === this.sortedTerms[i].Name.toLowerCase()) {
                return this.sortedTerms[i].Name;
            }
            if (this.term.toLowerCase().substring(0, this.term.length - 1) === this.sortedTerms[i].Name.toLowerCase()) {
                return this.sortedTerms[i].Name;
            }
        }
    }

    get definition() {
        for (let i = 0; i < this.sortedTerms.length; i++) {
            if (this.term.toLowerCase() === this.sortedTerms[i].Name.toLowerCase()) {
                return this.sortedTerms[i].Definition;
            }
            if (this.term.toLowerCase().substring(0, this.term.length - 1) === this.sortedTerms[i].Name.toLowerCase()) {
                return this.sortedTerms[i].Definition;
            }
        }
    }

    get link() {
        for (let i = 0; i < this.sortedTerms.length; i++) {
            if (this.term.toLowerCase() === this.sortedTerms[i].Name.toLowerCase()) {
                return this.sortedTerms[i].Link;
            }
            if (this.term.toLowerCase().substring(0, this.term.length - 1) === this.sortedTerms[i].Name.toLowerCase()) {
                return this.sortedTerms[i].Link;
            }
        }
    }

    private close() {
        this.dictionaryCard[0].classList.add('hidden');
    }

    // making dictionaryCard draggable

    private dragElement(element: any) {
        let pos1 = 0;
        let pos2 = 0;
        let pos3 = 0;
        let pos4 = 0;
        if (element !== undefined) {
            // if present, the header is where you move the DIV from:
            element.onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            element.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e: any) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e: any) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            element.style.top = (element.offsetTop - pos2) + 'px';
            element.style.left = (element.offsetLeft - pos1) + 'px';
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    private created() {
        EventBus.$on('hoverName', (data: any) => {
            this.term = data;
        });
    }

    private mounted() {
        this.dragElement(this.dictionaryCard[0]);
    }
}
