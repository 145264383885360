interface UploadProgress {
    time: number;
    value: number;
}


export class FileUploadData {
    public file: File;

    private progressSampleSize = 50;
    private progressIndex = 0;
    private progressItems: UploadProgress[] = [];

    constructor(file: File) {
        this.file = file;
    }

    // Set a rolling set of progress items
    set uploaded(value: number) {
        if (this.progressItems.length < this.progressSampleSize) {
            this.progressItems.push({ time: Date.now(), value: value });
        } else {
            this.progressItems.shift();
            this.progressItems.push({ time: Date.now(), value: value });
        }
    }

    get uploaded() {
        if (this.progressItems.length === 0) {
            return 0;
        }

        return this.progressItems[this.progressItems.length - 1].value;
    }



    /*----- Status/Info Getters -----*/

    get type() {
        return this.file.type;
    }

    get size() {
        return this.file.size;
    }

    get name() {
        return this.file.name;
    }

    get progress() {
        if (this.uploaded === 0) {
            return 0;
        }

        return ((this.uploaded / this.file.size) * 100).toFixed(2); // 0.00 -> 100.00
    }

    // Calculate the data transfer rate as a delta between progress items
    get speed() {
        if (this.progressItems.length === 0) {
            return 0;
        }

        const start = this.progressItems[0]; // Get first item
        const end = this.progressItems[this.progressItems.length - 1]; // Get last item

        const timeRange = end.time - start.time;
        const dataRange = end.value - start.value;

        const bytesPerMs = dataRange / timeRange;

        return Math.round((bytesPerMs * 1000)); // Bytes per second
    }
}
