



































































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import rules from '@/utility/ValidationRules';
import { AuthStore, StateStore } from '@/store/modules';
import { LoginForm, RegistrationForm } from '@/components';
import ApiService from '@/utility/ApiService';

const authStore = getModule(AuthStore);
const stateStore = getModule(StateStore);

@Component
export default class VerifyAndSetPassword extends Vue {
    private password = '';
    private verifyPassword = '';
    private userId: string | null = null;
    private token: string | null = null;

    private verificationComplete = false;
    private loading = false;
    private validForm = false;

    private rules = rules;

    private verifyPasswordRule(value: string) {
        return this.verifyPassword === this.password || 'Passwords Must Match';
    }

    get hasQueryParams() {
        return this.userId && this.token;
    }

    private backToLogin() {
        authStore.ClearErrors();
        this.$router.push({name: 'Login'});
    }

    private async setPassword() {
        if ((this.$refs.form as any).validate() && this.hasQueryParams) {
            authStore.ClearErrors();
            this.loading = true;
            const success = await authStore.SetPassword({userId: this.userId!, password: this.password});

            if (success) {
                this.verificationComplete = true;

                stateStore.SetSuccessToast({message: 'Account setup completed sucessfully, Please Login', timeout: 4000});
                authStore.ClearErrors();

                this.$router.push({name: 'Login'});
            } else {
                this.loading = false;
            }

        }

    }

    private async beforeMount() {
        if (this.$route.query.user) {
            this.userId = this.$route.query.user as string;
        }
        if (this.$route.query.code) {
            this.token = this.$route.query.code as string;

            this.loading = true;
            const success = await authStore.ConfirmEmail({userId: this.userId!, token: this.token!});

            if (!success) {
                this.token = null;
            }

            this.loading = false;
        }
    }
}
