import { SearchFlag, ExclusionFlag, SearchFilters, EntityFilterParams } from '@/models/interfaces';

const filterObject = (item: any, search: string, excludedKeys: Record<string, ExclusionFlag> = {}, level = 0): boolean => {
    const keys = Object.keys(item);

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        // key is excluded, and current depth is deeper that the keys max depth
        if (key in excludedKeys && level <= excludedKeys[key].maxDepth) {
            continue;
        }

        if (typeof item[key] === 'string') {
            if ((item[key] as string).toLowerCase().includes(search)) {
                return true;
            }
        } else if (Array.isArray(item[key])) {
            if (filterArray(item[key] as any[], search, excludedKeys, level + 1)) {
                return true;
            }
        } else if (typeof item[key] === 'object') {
            if (filterObject(item[key], search, excludedKeys, level + 1)) {
                return true;
            }
        }
    }

    return false;
};

const filterArray = (array: any[], search: string, excludedKeys: Record<string, ExclusionFlag> = {}, level = 0): boolean => {

    for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (typeof element === 'string') {
            if ((element as string).toLowerCase().includes(search)) {
                return true;
            }
        } else if (Array.isArray(element)) {
            if (filterArray(element as any[], search, excludedKeys, level + 1)) {
                return true;
            }
        } else if (typeof element === 'object') {
            if (filterObject(element, search, excludedKeys, level + 1)) {
                return true;
            }
        }
    }

    return false;
};

// any == true: Returns true on first match
// any = false: Returns true only if all match
/*const filterArrayByArray = (array: any[], comparedTo: any[], prop: string, any: boolean = true) => {
    for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < comparedTo.length; j++) {
            if (filterObject(array[i], ))
        }
    }
}*/

const filterEntityCollectionByIds = (item: any, prop: string, ids: Record<string, any>, exclusive: boolean) => {
    if (Object.keys(ids).length > 0) {

        // Item property doesn't exist, or has no items
        if ((!item[prop] || item[prop].length === 0) && exclusive) {
            if (exclusive) { // Exclusive filter, item doesn't have id, exclude
                return false;
            }
            return true; // Non-exclusive filter, item doesn't have id, include
        }

        for (let i = 0; i < item[prop].length; i++) {
            if (item[prop][i].id in ids) {
                if (exclusive) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        if (exclusive) {
            return false; // No match found, skip this item
        }
    }
    return true; // No tag filters, return true
};
// // start here.
const filterEntityCollectionByNames = (item: any, prop: string, names: Record<string, any>, exclusive: boolean) => {
    if (Object.keys(names).length > 0) {
        // Item property doesn't exist, or has no items
        if ((!item[prop] || item[prop].length === 0) && exclusive) {
            if (exclusive) { // Exclusive filter, item doesn't have id, exclude
                return false;
            }
            return true; // Non-exclusive filter, item doesn't have id, include
        }

        for (let i = 0; i < item[prop].length; i++) {
            if (item[prop][i].value in names || item[prop][i].principleValue in names) {
                if (exclusive) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        if (exclusive) {
            return false; // No match found, skip this item
        }
    }
    return true; // No tag filters, return true
};

const filterBySearch = (array: any[], search: string, excludedKeys: Record<string, ExclusionFlag> = {}) => {
    const output: any[] = [];
    const searchLower = search.toLowerCase();

    array.forEach((item) => {
        // Only search if search input exists
        if (search !== null && search !== undefined && search !== '') {
            if (filterObject(item, searchLower, excludedKeys)) {
                output.push(item);
            }
        }
    });

    return output;
};

// itemSearchProp is for search a property of an item, not the item itself, it does not change what is returned
const filterEntities = (args: EntityFilterParams, itemSearchProp?: string) => {
    const items = args.items;
    const search = args.search;
    const searchFlags = args.searchFlags;
    const filterFlags = args.filterFlags;

    const output: any[] = [];
    const searchLower = search.toLowerCase();
    const excludedKeys = getSearchFlagExclusions(searchFlags);

    items.forEach((item) => {

        let searchItem = item;
        if (itemSearchProp) {
            searchItem = item[itemSearchProp];
        }

        if (filterFlags.tags) {
            if (!args.tagsFilter || args.exclusiveTags === undefined) {
                throw Error('Missing required argument to filter tags');
            }
            if (Object.keys(args.tagsFilter).length > 0 && searchItem.metadata) { // Only filter if there are tags in the filter
                const include = filterEntityCollectionByIds(searchItem.metadata, 'tags', args.tagsFilter, args.exclusiveTags);
                if (include) {
                    output.push(item);
                    return;
                }
            }
        }

        if (filterFlags.properties) {
            if (!args.propertiesFilter || args.exclusiveProperties === undefined) {
                throw Error('Missing required argument to filter properties');
            }
            if (Object.keys(args.propertiesFilter).length > 0 && searchItem.metadata) {
                const include = filterEntityCollectionByNames(searchItem.metadata, 'propertyValues', args.propertiesFilter, args.exclusiveProperties);
                if (include) {
                    output.push(item);
                    return;
                }
            }
        }

        if (filterFlags.properties) {
            if (!args.propertiesFilter || args.exclusiveProperties === undefined) {
                throw Error('Missing required argument to filter properties');
            }
            if (Object.keys(args.propertiesFilter).length > 0 && searchItem.metadata) {
                const include = filterEntityCollectionByNames(searchItem.metadata, 'propertyValuePairs',
                    args.propertiesFilter, args.exclusiveProperties);
                if (include) {
                    output.push(item);
                    return;
                }
            }
        }

        // Only search if search input exists
        if (search !== null && search !== undefined && search !== '') {
            if (filterObject(searchItem, searchLower, excludedKeys)) {
                output.push(item);
            }
        }
    });

    return output;
};

const getSearchFlagExclusions = (flags: SearchFlag[]) => {
    const excludedKeys: Record<string, ExclusionFlag> = {
        id : {value: 'id', maxDepth: 100}
    };

    flags.forEach((flag) => {
        const maxDepth = flag.maxDepth === undefined ? 0 : flag.maxDepth;
        if (!flag.value) {
            if (typeof flag.props === 'string') {
                excludedKeys[flag.props] = {value: flag.props, maxDepth: maxDepth};
            } else { // Array
                flag.props.forEach((prop) => {
                    excludedKeys[prop] =  {value: prop, maxDepth: maxDepth};
                });
            }
        }
    });

    return excludedKeys;
};

export {
    filterObject,
    filterArray,
    filterBySearch,
    filterEntities,
    filterEntityCollectionByIds,
    getSearchFlagExclusions
};
