import { Version, SelectedProperties, SimpleEntity, SelectedProperty } from '@/models';
import validationRules from '@/utility/ValidationRules';
import { FileEditResponse } from './FileEditResponse';
import { groupArray } from '@/utility/Utilities';
import { EntityPropertyValue, EntityPropertyValuePair } from '../property';
import { FileSystemItem } from '../fileSystem';
import { CreateEditMetadataModel } from '../metadata';

export class CreateEditFileModel { // Cannot import from index due to circular dependency issues,  for some reason
    public id: string = '';
    public name: string = '';
    public title: string = '';
    public originalName: string = '';
    public fileExtension: string = '';
    public fileType: string = '';
    public version: Version = new Version(1, 0);
    public notes: string = '';

    public contentAccessLevel: string = '';

    public thumbnailId: string | null = null;

    public metadata: CreateEditMetadataModel;

    public sourceFiles: SimpleEntity[] = []; // selected related files

    // Used to maintain easier state for folder
    public selectedFolder: FileSystemItem | null = null;

    get sourceFileIds() {
        return this.sourceFiles.map((val) => {
            return val.id;
        });
    }

    // Gets a string from the version object
    get versionStr(): string {
        return `${this.version.majorVersion}.${this.version.minorVersion}`;
    }

    // Sets the version object
    set versionStr(value) {
        if (validationRules.version(value) === true) {
            const sections = value.split('.');
            this.version.majorVersion = Number(sections[0]);
            this.version.minorVersion = Number(sections[1]);
        }
    }

    constructor() {
        this.metadata = new CreateEditMetadataModel();
    }

    public FromResponse(model: FileEditResponse) {
        this.id = model.id;
        this.name = model.name;
        this.title = model.title;
        this.originalName = model.originalName;
        this.fileExtension = model.fileType.extension;
        this.fileType = model.fileType.name;
        this.version = model.version;
        this.notes = model.notes ?? '';

        this.contentAccessLevel = model.contentAccessLevel;

        this.thumbnailId = model.thumbnailId;

        this.selectedFolder = model.folder ? model.folder : null;

        this.sourceFiles = model.sourceFiles;

        this.metadata = new CreateEditMetadataModel();
        this.metadata.FromResponse(model.metadata);
    }
}
