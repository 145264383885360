import { NavigationGuard, Route, RawLocation } from 'vue-router';
import { StateStore, SearchStore, AuthStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';
import { RouteMeta, ToolbarConfig, RouteAction, PropsPromise } from '@/models/interfaces';
import { Dictionary, RouteRecord } from 'vue-router/types/router';
import RoutingService from '@/utility/RoutingService';

// const stateStore = getModule(StateStore);
// const searchStore = getModule(SearchStore);
// const authStore = getModule(AuthStore);

/**
 * Contains hooks and all their supporting logic to handle complex data retrieval rules, and progress reporting management for routes
 */
export class RouteManager {

    private static _stateStore: StateStore | null = null;
    static get stateStore() {
        if (!this._stateStore) {
            this._stateStore = getModule(StateStore);
        }

        return this._stateStore;
    }

    private static _searchStore: SearchStore | null = null;
    static get searchStore() {
        if (!this._searchStore) {
            this._searchStore = getModule(SearchStore);
        }

        return this._searchStore;
    }

    private static _authStore: AuthStore | null = null;
    static get authStore() {
        if (!this._authStore) {
            this._authStore = getModule(AuthStore);
        }

        return this._authStore;
    }



    public static beforeEach: NavigationGuard = async (to: Route, from: Route, next) => {
        if (RouteManager.stateStore.routeStepsComplete !== 0 ) {
            await RouteManager.stateStore.SetRouteStepsComplete(0);
        }
        await RouteManager.stateStore.SetRouteSteps(RouteManager.getRouteActionsCount(to.meta));
        await RouteManager.stateStore.SetLoading(true);

        // If not authenticated, do a full session check
        if (!RouteManager.authStore.Authenticated) {
            await RouteManager.authStore.CheckSession();
        } else { // Else only do a simple one
            await RouteManager.authStore.LocalSessionCheck();
        }
        await RouteManager.stateStore.IterateRouteStepsComplete();

        if (!RouteManager.authStore.Authenticated) {

            // If this is a login route, skip all actions to avoid infinite loop back to login
            if (to.name === 'Login' || to.name === 'ForgotPassword' || to.name === 'ResetPassword' || to.name === 'VerifyAndSetPassword') {
                next();
                return;
            }
            RoutingService.savePath(to.fullPath);
            next('/login'); // Redirect to login if not authenticated
        } else {

            if (to.meta.nav && to.meta.nav.title) {
                document.title = to.meta.nav.title;
            }
            RoutingService.destroyPath();
            next();
        }
    }

    public static beforeResolve: NavigationGuard = async (to, from, next) => {
        if (!from.fullPath.startsWith('/course/') || !to.fullPath.startsWith('/course/')) {
            RouteManager.stateStore.setPrevPath(from.fullPath);
        }

        if (RouteManager.shouldClearSearch(to.meta, from.meta)) {
            RouteManager.searchStore.ClearSearch();
        }

        const promises: Promise<any>[] = [];

        to.matched.forEach(async (route) => {
            let routePromise;
            // Don't try and queue up requests if not logged in
            if (RouteManager.authStore.Authenticated && route.meta.actions) {
                routePromise = RouteManager.resolveRouteActions(route, to.params, to.query);
            } else {
                RouteManager.stateStore.IterateRouteStepsComplete();
                routePromise = RouteManager.stateStore.SetLoading(false);
            }

            promises.push(routePromise);
        });

        await Promise.all(promises);

        RouteManager.setToolbarState(to.meta);
        next();
    }

    /**
     * Handles the assignment of a data result to a prop on a route component
     */
    public static handlePropsAssignment(route: Route, routeName: string) {
        if (!route.meta.actions) {
            throw new Error(`RouteMeta.actions is undefined in route props for '${routeName}'`);
        }
        if (!route.meta.actions.propsResults) {
            throw new Error(`RouteMeta.actions.propsResults is undefined in route props for '${routeName}'`);
        }

        if (!route.matched ||  route.matched.length <= 1) {
            return route.meta.actions.propsResults;
        }

        const record = route.matched.find((routeRecord) => routeRecord.name === routeName);

        if (!record) {
            throw new Error(`Unable to find matching RouteRecord for route: '${routeName}'`);
        }

        return record.meta.actions.propsResults;
    }

    /**
     * Determines if search queries should be cleared on this route change
     */
    private static shouldClearSearch = (toMeta: any, fromMeta: any) => {
        if (toMeta && toMeta.isSearch) {
            return false;
        }

        if (!toMeta || !fromMeta) { // Going to or from non-search, clear to be safe
            return true;
        }

        if (!toMeta.toolbar || !toMeta.toolbar.search) { // Going to a non-search route, clear search to be safe
            return true;
        }

        if (toMeta.toolbar && toMeta.toolbar.search && fromMeta.toolbar && fromMeta.toolbar.search) {
            if (fromMeta.toolbar.searchKey !== toMeta.toolbar.searchKey) {
                return true; // Going from one type of search to another, clear
            }
        }
        return false;
    }

    /**
     * Sets toolbar sorting and searching state in the SearchStore.
     */
    private static setToolbarState = (toMeta: RouteMeta) => {
        if (toMeta && toMeta.toolbar && toMeta.toolbar.search) {
            const config: ToolbarConfig = toMeta.toolbar;

            // Set Sort By Options
            if (config.sortByOptions && config.sortByOptions.length > 0) {
                RouteManager.searchStore.SetSortByOptions(config.sortByOptions);
            } else {
                RouteManager.searchStore.ClearSortByOptions();
            }

            // Set Search Flags
            if (config.searchFlags && config.searchFlags.length > 0) {
                RouteManager.searchStore.SetSearchFlags(config.searchFlags);
            } else {
                RouteManager.searchStore.ClearSearchFlags();
            }

            // Set Filter Flags
            if (config.filterFlags) {
                RouteManager.searchStore.SetSearchFilterFlags(config.filterFlags);
            } else {
                RouteManager.searchStore.ClearSearchFilterFlags();
            }
        } else {
            RouteManager.searchStore.ClearSortByOptions();
            RouteManager.searchStore.ClearSearchFlags();
            RouteManager.searchStore.ClearSearchFilterFlags();
        }
    }

    /**
     * Kicks off and the async & sync route action resolutions
     */
    // tslint:disable-next-line: max-line-length
    private static resolveRouteActions = async (to: Route | RouteRecord, routeParams: Dictionary<string>, query: Dictionary<string | (string | null)[]>) => {
        const meta: RouteMeta = to.meta;

        if (!meta.actions) {
            return;
        }

        RouteManager.startRouteActions(meta, routeParams, query);

        const blockingPromises = meta.actions.blockingPromises;
        const propsPromises = meta.actions.propsPromises;
        const promises = meta.actions.promises;
        routeParams.testProp = 'more testing';

        await RouteManager.stateStore.IterateRouteStepsComplete(); // Got to basic step 2/2, all route actions fired

        RouteManager.trackAllRouteActions(promises, blockingPromises, propsPromises);
        await RouteManager.handleRoutePropPromises(meta, propsPromises);
        await RouteManager.waitForRouteActions(blockingPromises);
        RouteManager.waitForRouteActions(promises);
    }

    /**
     * Parses & initializes the route actions and starts their execution
     */
    private static startRouteActions = (meta: RouteMeta, params: Dictionary<string>, query: Dictionary<string | (string | null)[]>) => {
        const actions = meta.actions;

        if (!actions) {
            return;
        }

        if (!actions.promises) {
            actions.promises = [];
        }
        if (!actions.blockingPromises) {
            actions.blockingPromises = [];
        }
        if (!actions.propsPromises) {
            actions.propsPromises = []; // Blocking by default
        }

        // Get data from normal endpoints
        if (actions.simple && actions.simple.length > 0) {
            actions.simple.forEach((action: any) => {
                if (typeof action === 'function') {
                    const func = action as () => Promise<any>;
                    actions.promises!.push(func());
                } else { // Is obj
                    const routeAction = action as RouteAction;
                    if (routeAction.prop) { // Is a props promise
                        actions.propsPromises!.push({
                            prop: routeAction.prop,
                            promise: routeAction.action()
                        });
                    } else if (routeAction.blocking) {
                        actions.blockingPromises!.push(routeAction.action());
                    } else {
                        actions.promises!.push(routeAction.action());
                    }
                }
            });
        }

        // Get data with options or manual query params
        if (actions.withOptions && actions.withOptions.length > 0) {
            actions.withOptions.forEach((optionAction) => {
                if (optionAction.prop) {
                    actions.propsPromises!.push({
                        prop: optionAction.prop,
                        promise: optionAction.action(optionAction.param)
                    });
                } else if (optionAction.blocking) {
                    actions.blockingPromises!.push(optionAction.action(optionAction.param));
                } else {
                    actions.promises!.push(optionAction.action(optionAction.param));
                }
            });
        }

        // Get data with query params
        if (actions.withQuery && actions.withQuery.length > 0) {
            actions.withQuery.forEach((queryAction) => {
                let promise: Promise<any>;

                if (typeof(queryAction.paramKeys) === 'string') { // Single query param
                    promise = queryAction.action(query[queryAction.paramKeys]);

                } else if (Array.isArray(queryAction.paramKeys)) { // Multiple query params
                    const args = queryAction.paramKeys.map((value) => query[value]);
                    promise = queryAction.action.apply(null, args);

                } else {
                    throw new Error('Expected query paramKeys of type string or array, got neither');
                }

                if (queryAction.prop) {
                    actions.propsPromises!.push({
                        prop: queryAction.prop,
                        promise: promise
                    });
                } else if (queryAction.blocking) {
                    actions.blockingPromises!.push(promise);
                } else {
                    actions.promises!.push(promise);
                }
            });
        }

        // Get data with route params
        if (actions.withRouteParams && actions.withRouteParams.length > 0) {
            if (!params) {
                throw Error('Missing params for requiredParamsData fetch');
            }

            actions.withRouteParams.forEach((paramsAction) => {
                let promise: Promise<any>;
                if (typeof(paramsAction.params) === 'string') {
                    promise = paramsAction.action(params[paramsAction.params]);
                } else if (Array.isArray(paramsAction.params)) {
                    const args = paramsAction.params.map((value) => params[value]);
                    promise = paramsAction.action.apply(null, args);
                } else {
                    throw new Error('Expected params of type string or array, got neither');
                }

                if (paramsAction.prop) {
                    actions.propsPromises!.push({
                        prop: paramsAction.prop,
                        promise: promise
                    });
                } else if (paramsAction.blocking) {
                    actions.blockingPromises!.push(promise);
                } else {
                    actions.promises!.push(promise);
                }
            });
        }
    }

    /**
     * Tracks all actions (blocking or not) steps in order to manage loading state
     */
    private static trackAllRouteActions = async (promises?: Promise<unknown>[], blockingPromises?: Promise<unknown>[],
                                                 propsPromises?: PropsPromise[]) => {
        if (blockingPromises) {
            await Promise.all(blockingPromises);
        }

        if (propsPromises) {
            const promisesItems: Promise<any>[] = [];
            propsPromises.forEach((propPromise) => {
                promisesItems.push(propPromise.promise);
            });

            await Promise.all(promisesItems);
        }

        if (promises) {
            await Promise.all(promises);
        }

        await RouteManager.stateStore.SetLoading(false);
    }

    /**
     * Waits for a set of actions to complete and iterates the loading steps as they resolve
     */
    private static waitForRouteActions = async (promises?: Promise<unknown>[]) => {
        if (!promises) {
            return;
        }

        for (let i = 0; i < promises.length; i++) {
            promises[i].then(() => {
                RouteManager.stateStore.IterateRouteStepsComplete();
            });
        }

        await Promise.all(promises);
    }

    /**
     * Handles route actions that are PropsPromise.
     */
    private static handleRoutePropPromises = async (meta: RouteMeta, propsPromises?: PropsPromise[]) => {
        if (!meta) {
            throw new Error('Cannot handle undefined or empty route meta');
        }
        if (!meta.actions) {
            throw new Error('Cannot handle undefined or empty RouteMeta actions');
        }
        if (!propsPromises || propsPromises.length === 0) {
            // tslint:disable-next-line: no-console
            console.error('Props promises is undefined or empty', propsPromises);
            return;
            // throw new Error('Cannot handle undefined or empty Props promises');
        }

        const promises: Promise<any>[] = [];
        const values: Record<string, any> = {};

        propsPromises.forEach((propPromise) => {
            promises.push(propPromise.promise);
            propPromise.promise.then((value) => {
                values[propPromise.prop] = value;
                RouteManager.stateStore.IterateRouteStepsComplete();
            });
        });

        await Promise.all(promises);

        meta.actions.propsResults = values;
        return values;
    }

    /**
     * Calculates the number of steps this route change needs to resolve
     */
    private static getRouteActionsCount = (meta: RouteMeta) => {
        let steps = 2;
        const actions = meta.actions;

        if (!actions) {
            return steps;
        }

        // Get data from normal endpoints
        if (actions.simple && actions.simple.length > 0) {
            steps += actions.simple.length;
        }

        // Get data with query params
        if (actions.withOptions && actions.withOptions.length > 0) {
            steps += actions.withOptions.length;
        }

        // Get data with route params
        if (actions.withRouteParams && actions.withRouteParams.length > 0) {
            steps += actions.withRouteParams.length;
        }

        return steps;
    }

}
