





import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '@/app.user/eventBus';


@Component
export default class IconImage extends Vue {
    @Prop({required: true, type: String}) public src!: string;
    @Prop({required: false, type: String}) public color!: string;
    @Prop({required: false, type: String, default: '100px'}) public size!: string;
    @Prop({required: false}) public to!: string | object;

    private getStyle() {
       // console.log(`mask: url("${this.src}");`)
        return `mask: url("${this.src}"); mask-size: cover; -webkit-mask: url("${this.src}"); -webkit-mask-size: cover;`;
    }
}
