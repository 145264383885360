






























































































































































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { AuthStore, StateStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';
import rules from '@/utility/ValidationRules';
import { LoginForm, RegistrationForm } from '@/components';
import { IconImage } from '@/app.user/components';
import TermsOfUse from '@/app.user/views/TermsOfUse.vue';

const authStore = getModule(AuthStore);
const stateStore = getModule(StateStore);

@Component({
  components: {
    LoginForm,
    IconImage,
    TermsOfUse
  }
})
export default class LoginLayout extends Vue {

  private mobile = false;
  private mobileNav = false;
  private windowWidth = window.innerWidth;

  private slides = [
        {title: 'CAD Toolkit', routeName: 'CadToolkit', image: '/images/HomepageSlider-CADToolkit.png'},
        {title: 'Training', routeName: 'Training', image: '/images/HomepageSlider-Training.png'},
        {title: 'Explore MBD', routeName: 'ExploreMBD', image: '/images/HomepageSlider-ExploreMBD.png'},
    ];

    private tab = 0;

    get sliderColor() {
      if (this.tab === 0) {
        return 'primary--text text--darken-1';
      } else if (this.tab === 1) {
        return 'orange--text text--darken-1';
      }
    }

    // Height of the errors toolbar section
    get errorsHeight() {
      return this.errors.length * 36;
    }

    // Extra height to add to the card for errors space
    get cardExtraErrorsHeight() {
      if (this.errors.length > 1) {
        return (this.errors.length - 1) * 36;
      }
      return 0;
    }

    /*get hasSuccessMessage() {
      if (this.$route.query.verifiedEmail) {
        return this.$route.query.verifiedEmail == 'true';
      }

      if (this.$route.query)
      return this.$route.query.verifiedEmail && this.$route.query.verifiedEmail == 'true';
    }

    get sucessMessage() {
      if (this.$route.query.verifiedEmail) {
        if (this.$route.query.verifiedEmail == 'true') {
          return 'Email Sucessfully Verified, Please Login';
        }
      }
    }*/

    @Watch('tab')
    private onTabChanged(val: number) {
      authStore.ClearErrors();
    }

    get errors() {
        return authStore.Errors;
    }

    get fieldErrors() {
      return authStore.FieldErrors;
    }

    get hasErrors() {
        return authStore.HasErrors;
    }

    get authenticated() {
        return authStore.Authenticated;
    }

    get component() {
        if (this.authenticated) {
        return 'layout';
        }
        return 'login';
    }

    get invalidToken() {
      return this.errors.length === 1 && this.errors[0].message === 'Invalid token.';
    }

    private errorMessage(error: string) {
      if (error === 'Invalid token.') {
        return 'Expired link';
      } else {
        return error;
      }
    }

    private hasFieldError(name: string) {
      if (this.fieldErrors === undefined) {
        return false;
      }

      if (this.fieldErrors[name] === undefined) {
        return false;
      }

      return true;
    }

    private getFieldError(name: string) {
      if (!this.hasFieldError(name)) {
        return '';
      }
      return this.fieldErrors[name].message;
    }

    private termsAndContact = document.getElementsByClassName('termsAndContact');
    private showTerms: boolean = true;

    private closeContact() {
      return this.showTerms = false;
    }

    private selectTermsOfUse: boolean = false;

    private noTerms() {
      return this.selectTermsOfUse = false;
    }

    private loginAlert: boolean = false;
    private routePath: any = localStorage.getItem('oscar_path') || undefined;

    private fromLink() {
      if (this.routePath !== undefined) {
        if (this.routePath.length > 1) {
          this.loginAlert = true;
          return this.loginAlert;
        } else {
          this.loginAlert = false;
          return this.loginAlert;
        }
      }
    }

    private checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 520) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    }

    private toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    }

    private onRouteChange() {
      this.mobileNav = false;
    }

    private created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    }

    private mounted() {
      // tslint:disable-next-line:triple-equals
      if (this.$route.query.verifiedEmail && this.$route.query.verifiedEmail == 'true') {
        stateStore.SetSuccessToastMessage('Email Sucessfully Verified, Your account will be activated shortly.');
        stateStore.ToggleSuccessToast(true);
      }
      this.fromLink();
    }
}

