import { SelectedProperties, SelectedProperty, EntityPropertyValue, EntityPropertyValuePair } from '@/models';
import { ICloneable } from '@/models/interfaces';
import validationRules from '@/utility/ValidationRules';
import { groupArray } from '@/utility/Utilities';
import { MetadataEditResponse } from './MetadataEditResponse';

// The translated model for editing metadata
// Or if the model has not been filled in from a response, for creating metadata
export class CreateEditMetadataModel implements ICloneable<CreateEditMetadataModel> {

    public tagIds: string[] = [];
    public selectedProperties: SelectedProperties = new SelectedProperties(); // Combined properties & paired properties

    public selectedStandards: string[] = [];
    public selectedMbdGuidelines: string[] = [];

    // Used in properties input to trigger watch to add new property
    public newPropertyId: string = '';

    // Adds a new object that will contain the property and it's values to the appropriate properties array
    // Called when a property is selected from the dropdown
    public onPropertySelect(propertyId: string) {
        this.newPropertyId = propertyId;
    }

    public FromResponse(model: MetadataEditResponse) {
        this.tagIds = model.tags.map((tag) => tag.id);

        this.selectedMbdGuidelines = model.mbdGuidelines ? model.mbdGuidelines.map((x) => x.id) : [];
        this.selectedStandards = model.standards ? model.standards.map((x) => x.id) : [];

        this.selectedProperties = this.getPropertyValues(model);
    }

    public Clone() {
        const clone = new CreateEditMetadataModel();

        clone.tagIds = this.tagIds;
        clone.selectedProperties = this.selectedProperties.Clone();
        clone.selectedStandards = this.selectedStandards;
        clone.selectedMbdGuidelines = this.selectedMbdGuidelines;
        clone.newPropertyId = this.newPropertyId;

        return clone;
    }

    private getPropertyValues(model: MetadataEditResponse) {
        const output: SelectedProperty[] = [];
        const selectedProperties = new SelectedProperties();

        if (model.multiSelectValues && model.multiSelectValues.length > 0) {
            const grouped = groupArray<EntityPropertyValue>(model.multiSelectValues, 'propertyId');
            Object.values(grouped).forEach((group) => {
                const prop = new SelectedProperty(group[0].id, group[0].propertyId!);
                const valueIds: string[] = [];
                group.forEach((value) => {
                    this.validateValue(value);
                    valueIds.push(value.valueId!);
                });
                prop.multiSelect = { valueIds: valueIds };

                output.push(prop);
            });

        }

        if (model.singleSelectValues && model.singleSelectValues.length > 0) {
            model.singleSelectValues.forEach((value) => {
                this.validateValue(value);
                const prop = new SelectedProperty(value.id, value.propertyId!);

                prop.singleSelect = { valueId: value.valueId };
                output.push(prop);
            });
        }

        if (model.valuePairs && model.valuePairs.length > 0) {
            model.valuePairs.forEach((value) => {
                this.validatePairedValue(value);
                const prop = new SelectedProperty(value.id, value.principlePropertyId!);

                prop.paired = {
                    principleId: value.principleValueId,
                    requiredId: value.requiredValueId
                };
                output.push(prop);
            });
        }

        selectedProperties.properties = output;
        return selectedProperties;
    }

    // Call one group at a time
    private validateValue(value: EntityPropertyValue) {
        if (value.valueId === undefined) {
            throw new Error('PropertyValue valueId is undefined');
        }
        if (value.propertyId === undefined) {
            throw new Error('PropertyValue propertyId is undefined');
        }
    }

    private validatePairedValue(value: EntityPropertyValuePair) {
        if (value.principleValueId === undefined) {
            throw new Error('PropertyValuePair principleValueId is undefined');
        }
        if (value.requiredValueId === undefined) {
            throw new Error('PropertyValuePair requiredValueId is undefined');
        }
        if (value.principlePropertyId === undefined) {
            throw new Error('PropertyValuePair principlePropertyId is undefined');
        }
        if (value.requiredPropertyId === undefined) {
            throw new Error('PropertyValuePair requiredPropertyId is undefined');
        }
    }
}
