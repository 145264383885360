import store from '@/store/store';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import JwtService from '@/utility/JwtService';
import ApiService from '@/utility/ApiService';
import { JwtValues, ErrorResponse } from '@/models';
import { ErrorResponseType } from '@/_common/enums';
import { AccountRegistrationRequest, CreateUserRequest } from '@/models/requests';

@Module({ namespaced: true, name: 'Auth', dynamic: true, store: store })
export default class AuthStore extends VuexModule {
    private authenticated = false;
    private token: TokenObj | null = null;
    private jwtValues: JwtValues | null = null;

    private errors: ErrorResponse[] = [];
    private fieldErrors: { [field: string]: ErrorResponse } = {};

    // State management to handle multiple CheckSession callers
    private checkingSession = false;
    private checkSessionPromise?: Promise<unknown>;

    get Errors() {
        const errors: ErrorResponse[]  = Array.from(this.errors);

        for (const key in this.fieldErrors) {
            if (this.fieldErrors.hasOwnProperty(key)) {
                errors.push(this.fieldErrors[key]);
            }
        }
        return errors;    }

    get FieldErrors() {
        return this.fieldErrors;
    }

    get HasErrors() {
        return (this.errors && this.errors.length > 0)
        || this.fieldErrors && Object.keys(this.fieldErrors).length > 0 ;
    }

    get Token(): TokenObj | null {
        return this.token;
    }

    get TokenValues(): JwtValues | null {
        return this.jwtValues;
    }

    get Authenticated(): boolean {
        return this.authenticated;
    }

    get CheckSessionPromise() {
        if (!this.checkSessionPromise) {
            return Promise.resolve();
        }
        return this.checkSessionPromise;
    }

    // Used only for internal state management
    @Mutation
    private setCheckingSession({status, promise}: {status: boolean, promise?: Promise<unknown>}) {
        this.checkingSession = status;
        this.checkSessionPromise = promise;
    }

    @Mutation
    private setErrors(errors: ErrorResponse[]) {
        this.errors = errors;
    }

    @Mutation
    private setFieldErrors(errors: { [field: string]: ErrorResponse } | undefined) {
        if (errors !== undefined) {
            this.fieldErrors = errors;
        } else {
            this.fieldErrors = {};
        }
    }

    @Mutation
    private setToken(token: TokenObj) {
        JwtService.saveToken(token);
        this.token = token;
        try {
            this.jwtValues = new JwtValues(JwtService.getTokenPayload(token.token));
        } catch { // Token failed to parse
            JwtService.destroyToken();
            store.dispatch('setFatalError', 'A Critical Error Has Ocurred. Please refresh the page');
        }
    }

    @Mutation
    private clearToken() {
        JwtService.destroyToken();
        this.token = null;
        this.jwtValues = null;
    }

    @Mutation
    private setAuthenticated(authenticated: boolean) {
        this.authenticated = authenticated;
    }

    @Action
    public async ClearErrors() {
        this.setErrors([]);
    }

    @Action({rawError: true})
    public async EnsureTokenIsSet() {
        const token = JwtService.getTokenObj();

        // Tokens are the same, return
        if (JSON.stringify(this.token) === JSON.stringify((token))) {
            return;
        // Vuex has token, localStorage does not. Keep Vuex
        } else if (this.token !== null && token === null) {
            this.context.commit('setToken', this.token);
        // Else set Vuex token with localStorage token
        } else {
            this.context.commit('setToken', token);
        }
    }

    @Action
    public async FetchAndSetToken() {
        const token = JwtService.getTokenObj();

        if (token !== null) {
            this.context.commit('setAuthenticated', true);
            this.context.commit('setToken', token);
        }
    }

    // Performs a simple local-only auth check.
    // Used during route change while the remote auth runs
    @Action({rawError: true})
    public async LocalSessionCheck() {
        await this.EnsureTokenIsSet();
        if (this.token === null) {
            this.context.commit('setAuthenticated', false);
            return;
        }

        if (this.jwtValues !== null) {

            // Token expired, do a full session check
            if (this.jwtValues.expired()) {
                await this.CheckSession();
            } else { // Token not expired, set authenticated
                this.setAuthenticated(true);
            }
        }
    }

    /*
        Can be safely called multiple times by multiple callers.
        Will await all callers until check is complete.
     */
    @Action({rawError: true})
    public async CheckSession() {

        // Avoid multiple callers
        if (this.checkingSession) {
            await this.CheckSessionPromise;
            return;
        }

        // Create promise that all callers can be awaited on
        let resolver!: (value?: unknown) => void;
        const checkPromise = new Promise((resolve) => {
            resolver = resolve;
        });
        this.setCheckingSession({status: true, promise: checkPromise});

        await this.EnsureTokenIsSet();

        if (this.token === null) {
            this.setAuthenticated(false);
            this.setCheckingSession({status: false});
            resolver();
            return;
        }

        const valid = await ApiService.ValidateSession();

        // Token not valid, attempt to refresh.
        if (!valid) {
            const result = await ApiService.RefreshSession(this.token);

            // Refresh failed, user is no longer authenticated
            if (result.errors && result.errors.errors.length > 0) {
                this.setAuthenticated(false);
                this.clearToken();

                if (result.errors.type === ErrorResponseType.Fatal) {
                    store.dispatch('setFatalError', result.errors.errors[0]);
                } else {
                    this.setErrors([{message: 'Your session has expired, please log back in'}]);
                }
            } else {
                this.setToken({token: result.token!, refreshToken: result.refreshToken!});
                this.setAuthenticated(true);
                this.setErrors([]);
            }
        } else { // Token valid, set authenticated state and continue
            this.setAuthenticated(true);
        }
        resolver();
        this.setCheckingSession({status: false});
    }

    @Action
    public async Logout(router: any) {
        if ( router.history.current.path !== '/') {
            await router.push('/');
        }
        this.clearToken();
        this.setAuthenticated(false);
        // HDB replaced go with push (5/22/23)
        await router.push('/login');
    }

    @Action({rawError: true})
    public async Login({username, password}: {username: string, password: string}) {
        const result = await ApiService.Login(username, password);

        if (result.token && result.refreshToken) {
            this.context.commit('setToken', {token: result.token, refreshToken: result.refreshToken});

            this.context.commit('setAuthenticated', true);
            this.setErrors([]);
            return true;
        } else {
            if ( result.errors) {
                this.setErrors(result.errors.errors);
                this.setFieldErrors(result.errors.fieldErrors);
            }
        }

        return false;
    }

    @Action({rawError: true})
    public async Register(model: AccountRegistrationRequest) {
        const result = await ApiService.Register(model);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors);
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action({rawError: true})
    public async CreateUser(model: CreateUserRequest) {
        const result = await ApiService.CreateUser(model);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors);
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action({rawError: true})
    public async EditUser(model: CreateUserRequest) {
        const result = await ApiService.EditUser(model);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors);
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action({rawError: true})
    public async SendEmailVerification({email}: {email: string}) {
        const result = await ApiService.SendEmailVerification(email);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors.concat(this.errors));
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action({rawError: true})
    public async ReSendEmailVerification({email}: {email: string}) {
        const result = await ApiService.ReSendEmailVerification(email);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors.concat(this.errors));
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action({rawError: true})
    public async sendAssessment({username, email, assessment, score, percent, bonus, answers}:
        {username: string, email: string, assessment: string, score: string, percent: string, bonus: string, answers: string}) {
        const result = await ApiService.SendAssessment(username, email, assessment, score, percent, bonus, answers);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors.concat(this.errors));
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action({rawError: true})
    public async RequestPasswordReset(email: string) {
        const result = await ApiService.RequestPasswordReset(email);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors.concat(this.errors));
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action({rawError: true})
    public async SendDowngradeUserEmail({email, sublevel}: {email: string, sublevel: string}) {
        const result = await ApiService.SendDowngradeUserEmail(email, sublevel);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors.concat(this.errors));
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action
    public async ResetPassword({userId, token, newPassword}: {userId: string, token: string, newPassword: string}) {
        const result = await ApiService.ResetPassword(userId, token, newPassword);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors.concat(this.errors));
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action
    public async SetPassword({userId, password}: {userId: string, password: string}) {
        const result = await ApiService.SetPassword(userId, password);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors.concat(this.errors));
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }

    @Action
    public async ConfirmEmail({userId, token}: {userId: string, token: string}) {
        const result = await ApiService.ConfirmEmail(userId, token);

        if (!result.errors) {
            this.setErrors([]);
            return true;
        } else {
            if (result.errors) {
                this.setErrors(result.errors.errors.concat(this.errors));
                this.setFieldErrors(result.errors.fieldErrors);
            }
            return false;
        }
    }
}
